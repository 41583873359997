<template lang="">
<div class='rightConcent' :style='"height:"+height+"px"' :class="title?'padding_top_0':''"> 
  <div class='tableContent' :style='"height:"+height+"px"'> 
    <div class="flexBox" v-if="title"> 
      <div class="headerTitle">{{ title }}</div> 
      <div class="backBtn" @click="goBack">返回</div>
    </div> 
  <div class='content' :style='{"height":(title?height-45:height)+"px"}'>
    <router-view></router-view> 
  </div>
  
</div> 
  </div>
</template>
<script>
import { ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
export default {
  setup() {
    const height = ref(document.documentElement.clientHeight - 71);
    // 监听浏览器尺寸变化
    const goBack = () => {
      router.go(-1);
    };
    onMounted(() => {
      window.addEventListener("resize", () => {
        // 监听页面尺寸变化
        height.value = document.documentElement.clientHeight - 71;
      });
    });
    const route = useRoute();
    const title = ref((route.name && route.name.title) || "");
    watch(
      () => route.fullPath,
      () => {
        title.value = (route.name && route.name.title) || "";
      },
      { deep: true }
    );
    return {
      height,
      title,
      goBack,
    };
  },
};
</script>
<style lang="less" scoped>
.rightConcent {
  padding-left: 15px;
  padding-right: 15px;
  .headerTitle {
    border-left: 3px solid #3e7fff;
    padding-left: 6px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    margin: 7px 0 8px;
  }
  .flexBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    .backBtn {
      margin-left: auto;
      width: 46px;
      height: 24px;
      border-radius: 2px;
      border: solid 1px #dbe0ec;
      text-align: center;
      line-height: 24px;
      color: #3e4056;
      font-size: 12px;
      align-self: center;
      cursor: pointer;
    }
  }
  .breadTop {
    margin-bottom: 18px;
  }
  .arco-breadcrumb {
    display: flex;
  }
  .tableContent {
    overflow-y: scroll;
  }
  .tableContent::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .tableContent {
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
.padding_top_0 {
  padding-top: 0;
}
</style>
