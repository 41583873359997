export default [
{
    path: 'clientManage',
    name: {list:['产业中心','设备','客户端管理','客户端管理']},
    component: () => import('@/views/equipment/clientList.vue')
},
{
    path: 'equEntpManage',
    name: {list:['产业中心','设备','设备管理','厂商管理']},
    component: () => import('@/views/equipment/equEntpList.vue')
},
    {
        path: 'equManage',
        name: {list:['产业中心','设备','设备管理','设备管理']},
        component: () => import('@/views/equipment/equInfoList.vue')
    }
]