<template>
	<div class="shop-info">
		<div class="evaluateHeader" v-if="unitData.img||unitData.name||unitData.producer">
			<div class="imgPj" v-if="unitData.img"><img :src="commenUrl + data.productUrl" /></div>
			<div class="pjContent">
				<div class="bigT" v-if="unitData.name" :class="'font-color-'+colorValue">{{data.productName}}</div>
				<div class="smallT" v-if="unitData.producer">{{data.address}}</div>
			</div>
		</div>
		<div class="evaluateContent" v-if="unitData.apply||unitData.examine||unitData.date||unitData.project">
			<div class="evaluateLi" v-if="unitData.apply">
				申请单位：{{ data.companyName }}
			</div>
			<div class="evaluateLi" v-if="unitData.examine">
				审核单位：{{data.auditUnitName }}
			</div>
			<div class="evaluateLi" v-if="unitData.date">
				评价时间：{{ data.appraiseTime.substr(0,7) }}
			</div>
			<div class="evaluateLi" v-if="unitData.project">
				评价项目：{{ '感官品质指标、理化指标、特征品质指标' }}
			</div>
		</div>
		<div class="describe" v-if="unitData.describe">
			{{ unitData.describe }}
		</div>
	</div>
</template>

<script>
	export default {
		name:"shop-info",
		props: {
			unitData:{
				type:Object,
				default(){
					return {
						infoType:'big',//整体外形
						shopTit:'店铺名称',//店铺名称
						shopNum:'0',//商品数量
						backGroundUrl:''
					}
				}
			},
			colorValue:{
				type:String,
				default(){
					return 'orange';
				}
			},
			data:{
				type:Object,
				default(){
					return {
					}
				}
			}
		},
		data() {
			return {
				commenUrl:this.commenUrl,
			};
		},
		mounted() {
		},
		methods:{
		}
	}
</script>

<style lang="less">
.shop-info{
	background-color: #FFFFFF;
	padding: 1.66rem 0 0;
	border-radius: 0.89rem;
	margin:0 auto;
	margin-bottom: 10px;
	.evaluateHeader {
		width: 32rem;
		height: 6.19rem;
		display: flex;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		padding: 0 1.06rem 1.06rem;
		.imgPj {
			width: 6.19rem;
			height: 6.19rem;
			border-radius: 0.89rem;
			img {
				width: 6.19rem;
				height: 6.19rem;
				border-radius: 0.89rem;
			}
		}

		.pjContent {
			margin-left: 1.06rem;
			padding: 1.11rem 0;
			div {
				margin-bottom: 0.44rem;
				width: 22.05rem;
				overflow: hidden;
				word-break: break-all;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.bigT {
				font-size: 1.78rem;
				height: 2.44rem;
				line-height: 2.44rem;
				font-size: 1.77rem;

			}

			.smallT {
				height: 2rem;
				font-family: PingFangSC-Regular;
				font-size: 1.15rem;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0;
				color: #000000;
			}
		}
	}
	.evaluateContent{
		padding: 0 1.06rem 1.06rem;
		.evaluateLi{
			font-family: PingFangSC-Regular;
			font-size: 1.06rem;
			font-weight: normal;
			font-stretch: normal;
			// height: 2.66rem;
			line-height: 2.66rem;
			letter-spacing: 0;
			color: #333333;
		}
	}
	.describe{
		// width: 36.24rem;
		font-family: PingFangSC-Regular;
		font-size: 1.06rem;
		font-weight: normal;
		font-stretch: normal;
		line-height: 2.66rem;
		letter-spacing: 0;
		color: #666666;
		padding: 0 1.06rem 1.66rem;
	}
}
</style>