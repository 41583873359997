<template>
	<div class="shortcut-menu" :class="{'more-menu':unitData.menuNumber>4}">
		<div class="menu-list" :style="{'text-align':unitData.menuList.length<3?'center':'left'}">
			<div class="menu-item" :style="{'color':colorList[index].font,'background-color':colorList[index].background}" v-for="(item,index) in unitData.menuList" :key="index">
				<div class="imagebg">
					<div class="text">
						<!-- <div class="mes" :style="{'background-color':colorList[index].font}"></div> -->
						<div class="title">{{item.title}}</div>
					</div>
					<div class="number">{{item.urlGo}}</div>
				</div>
				<div class="shadow"></div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		name:"shortcut-menu",
		props:{
			unitData:{
				type:Object,
				default:{
					menuNumber:4,
					menuList:[]
				}
			}
		},
		data() {
			return {
				commenUrl:this.commenUrl,
				colorList:[
					{
						font:'#f88633',
						background:'#ffeee2'
					},
					{
						font:'#ee546f',
						background:'#ffecec'
					},
					{
						font:'#4378e2',
						background:'#ecf3ff'
					},
					{
						font:'#50AC3A',
						background:'#EFF7ED'
					}
				]
			};
		},
		computed:{
			newmenuList(){
				if(this.unitData.menuNumber>4){
					return this.unitData.menuList
				}else{
					let arr=JSON.parse(JSON.stringify(this.unitData.menuList));
					arr=arr.splice(0,4);
					return arr
					// let arr=this.unitData.menuList.splice(0,3)
					// return arr
				}
			}
		},
		methods:{
			// 路由跳转
			goPage(url,id){
				var params = {};
				if(id){
					params = {id:id}
				}
				this.$w.route({
					type:'to',
					params:params,
					url:url
				})
			},
		},
		
	}
</script>

<style lang="less" scoped>
.shortcut-menu{
	margin:0 auto;
	margin-bottom: 10px;
	// display: flex;
	// align-items: center;
	// justify-content:space-between;
	// overflow-x: scroll;
	.menu-list{
		padding: 1.06rem 1.06rem 0.89rem;
		background-color: #fff;
	}
	.menu-item{
		// text-align: center;
		// float: left;
		display: inline-block;
		margin-bottom: 0.44rem;
		.imagebg{
			border-radius: 0.44rem;
			display: inline-block;
			height: 4.78rem;
			padding: 0.71rem;
			overflow: hidden;
			width: 7.48rem;
			.mes{
				display: inline-block;
				width: 0.55rem;
				height: 0.55rem;
				border-radius: 0.28rem;
				margin-top: 0.67rem;
			}
			.title{
				display: inline-block;
			}
			.text{
				line-height: 1.88rem;
				font-size: 1.06rem;
				height: 1.88rem;
				width: 7.6rem;
				text-align: center;
				// overflow: hidden;
				// word-break: break-all;
				// text-overflow: ellipsis;
				// white-space: nowrap;
			}
			.number{
				width: 7.60rem;
				height: 2.55rem;
				line-height: 2.55rem;
				font-family: DIN-Black;
				font-size: 1.8rem;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0;
				overflow: hidden;
				word-break: break-all;
				text-overflow: ellipsis;
				white-space: nowrap;
				margin-top: 0.22rem;
				text-align: center;
			}
		}
		.shadow{
			width: 7.20rem;
			height: 0.11rem;
			box-shadow: 0 0.22rem 0.44rem 0 
				rgba(0, 0, 0, 0.5);
			// border: solid 0.11rem #ffffff;
			margin-top: -0.3rem;
			margin-left: 0.89rem;
		}
	}
	.menu-item:nth-child(2){
		margin: 0 1.06rem;
	}
	.five{
    	margin-right: 1.77rem;
	}
	.five:last-child{
		margin-right: 0;
	}
	.four{
    	margin-right: 5.10rem;
	}
	.four:last-child{
		margin-right: 0;
	}
}

</style>
