<template>
  <div class="leftMenu">
    <div class="firstLine" v-if="menuList && menuList.length > 0">
      <div
        class="itemMenu"
        v-for="(item, index) in menuList"
        :key="index"
        @click="menuClick(index)"
        :class="menuIndex == index ? 'chooseMenu' : ''"
      >
      <svg v-if="menuIndex == index" width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>选中菜单</title>
        <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="首页风格框架" transform="translate(-27.000000, -97.000000)" fill="#3E7FFF" fill-rule="nonzero">
                <g id="选中菜单" transform="translate(27.000000, 97.000000)">
                    <path d="M1.05950564,1.38745641 L5.92957101,1.38745641 C6.47615625,1.38745641 6.74944702,1.66074903 6.74944702,2.20733242 L6.74944702,7.0773978 C6.74944702,7.62398303 6.47615441,7.89727381 5.92957101,7.89727381 L1.05950564,7.89727381 C0.512920404,7.89727381 0.239629628,7.62398119 0.239629628,7.0773978 L0.239629628,2.20733242 C0.239629628,1.66074719 0.512922245,1.38745641 1.05950564,1.38745641 Z" id="路径"></path>
                    <path d="M1.05950564,9.98001328 L5.92957101,9.98001328 C6.47615625,9.98001328 6.74944702,10.2533059 6.74944702,10.7998893 L6.74944702,15.6699547 C6.74944702,16.2165399 6.47615441,16.4898307 5.92957101,16.4898307 L1.05950564,16.4898307 C0.512920404,16.4898307 0.239629628,16.2165381 0.239629628,15.6699547 L0.239629628,10.7998893 C0.239629628,10.2533041 0.512922245,9.98001328 1.05950564,9.98001328 Z" id="路径"></path>
                    <path d="M10.0866005,9.98001328 L14.9566658,9.98001328 C15.5032511,9.98001328 15.7765418,10.2533059 15.7765418,10.7998893 L15.7765418,15.6699547 C15.7765418,16.2165399 15.5032492,16.4898307 14.9566658,16.4898307 L10.0866005,16.4898307 C9.54001522,16.4898307 9.26672444,16.2165381 9.26672444,15.6699547 L9.26672444,10.7998893 C9.26672444,10.2533041 9.54001706,9.98001328 10.0866005,9.98001328 Z" id="路径"></path>
                    <path d="M8.49827104,4.06024406 L11.9419319,0.616583247 C12.3284245,0.230090554 12.7149191,0.230090554 13.1014118,0.616583247 L16.5450726,4.06024406 C16.9315653,4.44673676 16.9315653,4.83323129 16.5450726,5.21972398 L13.1014118,8.6633848 C12.7149191,9.04987749 12.3284245,9.04987749 11.9419319,8.6633848 L8.49827104,5.21972398 C8.11177834,4.83323129 8.11177834,4.44673676 8.49827104,4.06024406 Z" id="路径"></path>
                </g>
            </g>
        </g>
      </svg>
      <svg v-else width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>默认菜单</title>
        <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="首页风格框架" transform="translate(-27.000000, -163.000000)" fill="#7C879B" fill-rule="nonzero">
                <g id="默认菜单" transform="translate(27.000000, 163.000000)">
                    <path d="M1.12572474,0.411672436 L6.3001692,0.411672436 C6.88091601,0.411672436 7.17128746,0.702045841 7.17128746,1.2827907 L7.17128746,6.45723516 C7.17128746,7.03798197 6.88091406,7.32835342 6.3001692,7.32835342 L1.12572474,7.32835342 C0.544977929,7.32835342 0.25460648,7.03798001 0.25460648,6.45723516 L0.25460648,1.2827907 C0.25460648,0.702043885 0.544979885,0.411672436 1.12572474,0.411672436 Z" id="路径"></path>
                    <path d="M10.6882247,0.411672436 L15.8626692,0.411672436 C16.443416,0.411672436 16.7337875,0.702045841 16.7337875,1.2827907 L16.7337875,6.45723516 C16.7337875,7.03798197 16.4434141,7.32835342 15.8626692,7.32835342 L10.6882247,7.32835342 C10.1074779,7.32835342 9.81710648,7.03798001 9.81710648,6.45723516 L9.81710648,1.2827907 C9.81710648,0.702043885 10.1074799,0.411672436 10.6882247,0.411672436 Z" id="路径备份"></path>
                    <path d="M1.12572474,9.54126411 L6.3001692,9.54126411 C6.88091601,9.54126411 7.17128746,9.83163751 7.17128746,10.4123824 L7.17128746,15.5868268 C7.17128746,16.1675736 6.88091406,16.4579451 6.3001692,16.4579451 L1.12572474,16.4579451 C0.544977929,16.4579451 0.25460648,16.1675717 0.25460648,15.5868268 L0.25460648,10.4123824 C0.25460648,9.83163555 0.544979885,9.54126411 1.12572474,9.54126411 Z" id="路径"></path>
                    <path d="M10.717013,9.54126411 L15.8914574,9.54126411 C16.4722043,9.54126411 16.7625757,9.83163751 16.7625757,10.4123824 L16.7625757,15.5868268 C16.7625757,16.1675736 16.4722023,16.4579451 15.8914574,16.4579451 L10.717013,16.4579451 C10.1362662,16.4579451 9.84589472,16.1675717 9.84589472,15.5868268 L9.84589472,10.4123824 C9.84589472,9.83163555 10.1362681,9.54126411 10.717013,9.54126411 Z" id="路径"></path>
                </g>
            </g>
        </g>
      </svg>
    {{ item.name }}
      </div>
    </div>
    <div
      class="secondLine"
      v-if="
        menuList[menuIndex] &&
        menuList[menuIndex].children &&
        menuList[menuIndex].children.length > 0
      "
    >
      <div
        v-for="(innerItem, innerIndex) in menuList[menuIndex].children"
        :key="innerIndex"
      >
        <div class="itemMenu" @click="secondClick(innerIndex)">
          {{ innerItem.name }}
          <el-icon style="vertical-align: middle" color="#333333"><ArrowDown /></el-icon>
        </div>
        <div v-if="innerItem && innerItem.children">
          <div v-for="(threeItem, threeIndex) in innerItem.children" :key="threeIndex">
            <div
              class="itemThree"
              @click="threeClick(threeIndex, threeItem, innerIndex)"
              :class="
                threeMIndex == threeIndex && innerMIndex == innerIndex ? 'colorMenu' : ''
              "
            >
              {{ threeItem.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, watch, onMounted } from "vue";
import { ArrowDown } from "@element-plus/icons";
import { useRouter, useRoute } from "vue-router"; 
export default {
  name: "homePage",
  components: {ArrowDown},
  props: {
    list: Array,
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const menuList = ref([]);
    const menuIndex = ref(0);
    const secondIndex = ref(0);
    const innerMIndex = ref(0);
    const threeMIndex = ref(0);
    // const menuAllList = ref([])
    const oneList = ref([]);
    const twoList = ref([]);
    const threeList = ref([]);
    const menuShow = () => {
      if (route.path === "/" && menuList.value.length > 0) {
        router.push(menuList.value[0].children[0].children[0].href);
        return;
      }
      if (route.name && route.name.list) {
        if (route.name.list[1]) {
          menuIndex.value = menuList.value.findIndex((e) => e.name == route.name.list[1]);
          oneList.value = menuList.value[menuIndex.value];
          if (route.name.list[2] && oneList.value) {
            innerMIndex.value = oneList.value.children.findIndex(
              (e) => e.name == route.name.list[2]
            );
            twoList.value = oneList.value.children[innerMIndex.value];
            if (route.name.list[3] && innerMIndex.value > -1) {
              threeMIndex.value = twoList.value.children.findIndex(
                (e) => e.name == route.name.list[3]
              );
              threeList.value = twoList.value.children[threeMIndex.value];
            } else {
              innerMIndex.value = 0;
              threeMIndex.value = 0;
            }
          } else {
            menuIndex.value = menuIndex.value==-1?0:menuIndex.value
            innerMIndex.value = 0;
            threeMIndex.value = 0;
          }
        } else {
          menuIndex.value = 0;
          innerMIndex.value = 0;
          threeMIndex.value = 0;
        }
      } else {
        menuIndex.value = 0;
        innerMIndex.value = 0;
        threeMIndex.value = 0;
      }
    };
    watch(
      () => props.list,
      (newValue) => {
        if (newValue) {
          menuList.value = newValue;
          menuIndex.value = 0;
          console.log("数据变化");
          menuShow();
        }
      }
    );
    watch(
      () => route.path,
      () => {
        console.log("地址变化");
        menuShow();
      }
    );
    const secondClick = (index) => {
      secondIndex.value = index;
    };
    const menuClick = (index) => {
      menuIndex.value = index;
      innerMIndex.value = 0;
      threeMIndex.value = 0;
      if (menuList.value[index].children) {
        if (menuList.value[index].children[0].children[0].href) {
          router.push(menuList.value[index].children[0].children[0].href);
        } else {
          proxy.$message({
            message: "暂未配置地址",
            center: true,
            type: "error",
            duration: 2000,
          });
        }
      }
      secondIndex.value = 0;
      threeMIndex.value = 0;
      // console.log(menuIndex.value)
    };
    const threeClick = (index, e, innerIndex) => {
      console.log(index)
      console.log(innerIndex)
      innerMIndex.value = innerIndex;
      threeMIndex.value = index;
      if (e.href) {
        router.push(e.href);
      } else {
        proxy.$message({
          message: "暂未配置地址",
          center: true,
          type: "error",
          duration: 2000,
        });
      }
    };

    onMounted(() => {
      if (menuList.value.length == 0) return;
      console.log("初始化");
      menuShow();
    });
    // const menuInit = (id)=>{
    //     proxy
    //     .$request({
    //       url: 'AUTH_LIST',
    //       data: {
    //             id:id
    //         },
    //     })
    //     .then((res) => {
    //       menuAllList.value = res.data.result
    //       menuList.value = res.data.result.find(e=>e.id==id)?.children
    //     })
    //     .catch((err) => {
    //         console.log(err)
    //     });
    // }
    // if(props.id)menuInit(props.id)
    return {
      menuList,
      menuIndex,
      menuClick,
      secondIndex,
      secondClick,
      threeMIndex,
      threeClick,
      innerMIndex,
    };
  },
};
</script>

<style scoped lang="less">
.leftMenu {
  display: flex;
  // background-color: #ecf1ff;
  width: 240px;
  .firstLine,
  .secondLine {
    background-color: #ecf1ff;
    padding: 20px 0;
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    .itemMenu {
      text-align: center;
      width: 98px;
      height: 50px;
      line-height: 50px;
      color: #7c879b;
      cursor: pointer;
    }
    .chooseMenu {
      background: #ffffff;
      color: #3e7fff;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }
  .secondLine {
    display: flex;
    background: white;
    padding: 20px 10px;
    .itemMenu {
      font-size: 14px;
      padding-left: 10px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      font-size: 14px;
      color: #333333;
      background: white;
      display: flex;
      cursor: pointer;
      .el-icon {
        margin-left: auto;
        align-self: center;
      }
    }
    .itemThree {
      cursor: pointer;
      padding-left: 10px;
      width: 100px;
      height: 40px;
      line-height: 40px;
      color: #7c879b;
    }
    .colorMenu {
      background-color: #ecf1ff;
      color: #3e7fff;
    }
  }
}
</style>
