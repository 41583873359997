let Api = {
	// 合格证
	"CERTIFICATEINFO_FINDPAGELIST": "/quality/w/CertificateInfo/findPageList", //分页查询出证记录列表
	"CERTIFICATEINFO_FINDPAGESCANRECODERLIST": "/quality/w/CertificateInfo/findPageScanRecoderList", //分页查询扫码列表
	"CERTIFICATEINFO_SAVEORUPDATE": "/quality/w/CertificateInfo/saveOrUpdate", //新增保存 - 为pdf文件
	"ENTPAUTH_GETDETAIL": "/system/w/entpAuth/getDetail", //查询认证信息
	"W_EVALUATION_LABELPAGE": "/quality/w/evaluation/labelPage", //查询申请标签列表
	"W_EVALUATION_FINDPAGELIST": "/quality/w/evaluation/findPageList", //评价项目管理列表查询
	"W_EVALUATION_SAVEAPPLICATIONLABEL": "/quality/w/evaluation/saveApplicationLabel", //申请评价标签
	"N_EVALUATION_GEOPRO_SCANLABELINFO": "/quality/n/geoPro/scanLabelInfo", //数字品质 - 标签/扫描查询
	
	"ENTPBASEMAGE_FINDPAGELIST": "/difam/w/entpBaseMage/findPageList", //基地列表
	
	"ENTPBASEMAGE_FINDINFO": "/difam/w/entpBaseMage/findInfo", //基地详情
}
module.exports = Api