let Api = {
	"CATEGORYMAGE_TREE": "/system/w/categoryMage/findCommMageTree", //分类列表
    "CATEGORYMAGE_SAVEORUPDATE": "/system/w/categoryMage/saveOrUpdate", //分类新增or修改
    "CATEGORYMAGE_DEL": "/system/w/categoryMage/del", //分类删除
	"CATEGORYMAGE_PAGETREE": "/system/w/categoryMage/findPageCommMageTree", //分类列表


    "COM_FINDMAGELIST": "/system/w/commVarietMage/findCommVarierMageList", //产品列表分页
    "COM_MAGESAVEORUPDATE": "/system/w/commVarietMage/saveOrUpdate", //产品新增or编辑
    "COM_MAGEDELETE": "/system/w/commVarietMage/delete", //产品删除

    
	"CATEGORYPLANT_TREE": "/system/w/PlantCategoryMage/findCommMageTree", //作物分类列表
    "CATEGORYPLANT_SAVEORUPDATE": "/system/w/PlantCategoryMage/saveOrUpdate", //作物分类新增or修改
    "CATEGORYPLANT_DEL": "/system/w/PlantCategoryMage/del", //作物分类删除
	"CATEGORYPLANT_PAGETREE": "/system/w/PlantCategoryMage/findPageCommMageTree", //作物分类列表


    "COM_FINDPLANTELIST": "/system/w/PlantVarietyMage/findCommVarierMageList", //作物品种列表分页
    "COM_PLANTSAVEORUPDATE": "/system/w/PlantVarietyMage/saveOrUpdate", //作物品种新增or编辑
    "COM_PLANTDELETE": "/system/w/PlantVarietyMage/delete", //作物品种删除

    "COM_MAGE_EXPORT": "/system/w/commVarietMage/exportCommVarietMage",// 平台产品
    "COM_CATEGORYMAGE_EXPORT": "/system/w/categoryMage/exportCategoryMage",// 平台品类

    "COM_PLANT_EXPORT": "/system/w/PlantVarietyMage/exportCommVarietMage",// 作物品种
    "COM_PLANTMAGE_EXPORT": "/system/w/PlantCategoryMage/exportCategoryMage",// 作物品类
}
module.exports = Api