export default [
    {
        path: 'yopMerchant',
        name: {list:['产业中心','易宝','易宝商户','商户列表']},
        component: () => import('@/views/yop/yopMerchantList.vue')
    },
    {
        path: 'yopReconcile',
        name: {list:['产业中心','易宝','对账单','收款对账单']},
        component: () => import('@/views/yop/yopReconcileList.vue')
    },
    {
        path: 'yopTradeBill',
        name: {list:['产业中心','易宝','对账单','交易对账单']},
        component: () => import('@/views/yop/yopTradeBillList.vue')
    },
    {
        path: 'yopDivideBill',
        name: {list:['产业中心','易宝','对账单','分账对账单']},
        component: () => import('@/views/yop/yopDivideBillList.vue')
    },
    {
        path: 'yopRequest',
        name: {list:['产业中心','易宝','易宝接口','易宝接口']},
        component: () => import('@/views/yop/yopRequest.vue')
    },
]