import { createRouter, createWebHistory } from 'vue-router';
import appRoutes from './routes';
import { getCookie } from '../../public/js/cookie'

import Home from '@/layout/page.vue';
import contentR from '@/components/contentR/rightConcent.vue';
import Login from '@/views/user/login.vue';
import emptyCount from '@/views/evaluationLabel/empty.vue';

const router = createRouter({
  history: createWebHistory("/admin/"),
  routes: [
    {
      name: '登录',
      path: '/login',
      component: Login,
    },
    {
      name: '首页',
      path: '/',
      component: Home,
      children: [
        {
            name: '',
            path: '',
            component: contentR,
            children: appRoutes,
          }
      ],
    },
    {
      path: '/customEdit',
      name: {list:['数字基地','门户','门户配置','门户推广']},
      component: () => import('@/views/customPage/edit/index.vue')
    },
    {
      name:'商品详情',
      path: '/productDetails',
      component: () => import('@/views/digitalShop/productDetails.vue')
    },
    {
      path: '/reviewPreview',
      name: '项目信息预览',
      component: () => import('@/views/transaction/assessment/reviewPreview.vue') 
    },
    {
      path: '/emptyCount',
      name: '项目信息',
      component:emptyCount
    },
  ],
  // scrollBehavior (to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return {
  //       x: 0,
  //       y: 0
  //     }
  //   }
  // }
  scrollBehavior() {
    return { top: 0 };
  },
});
router.beforeEach((to, from, next) => {
  const user = getCookie("userInfo");
  console.log(from)
  if (!user && to.path !== '/login'&&to.path!=='/emptyCount') {
      next('/login');
  }else{
      next();
  } 
});


// createRouteGuard(router);
console.log(router,'router')
export default router;


