<template>
	<div class="i-video">
		<div class="video-nomal margin_tb_10" v-if="unitData.url">
			<div class="video-nomal" v-if="unitData.img">
				<video-player v-if="show" :src="unitData.from == 'local' ? commenUrl + unitData.url : unitData.url"
					:poster="unitData.img ? commenUrl + unitData.img : ''" />
			</div>
			<div class="video-nomal" v-else>
				<video-player v-if="show" :src="unitData.from == 'local' ? commenUrl + unitData.url : unitData.url" />
			</div>
			<div class="video-titleR" v-if="unitData.show">{{ unitData.name }}</div>
		</div>
		<div class="video-img margin_tb_10 mr-videoR" :class="['theme-color-img-' + colorValue]" v-else>
			<img class="mrImg" src="../../../assets/images/shopEdit/video.png" alt="">
		</div>

	</div>
</template>

<script>
import VideoPlayer from '@/components/videoPlayer.vue';
export default {
	name: "video-panel",
	components: { VideoPlayer },
	props: {
		unitData: {
			type: Object,
			default: {
				name: '',
				show: true,
				from: 'local',
				url: '',
				img: ''
			}
		},
		colorValue: {
			type: String,
			default() {
				return 'orange';
			}
		},
	},
	mounted() {
		// console.log(this.unitData)
		setTimeout(() => {
			this.show = true
		}, 500)
	},
	data() {
		return {
			commenUrl: this.commenUrl,
			show:false
		};
	},
	methods: {
	},
	onmounted() {
		// console.log(this.unitData)
		this.show = false
	},


}
</script>

<style lang="less">
.i-video {
	margin: 0 auto;
	margin-bottom: 10px;

	.video-nomal {
		// padding: 1.77rem 0;
		height: 17.73rem;
		position: relative;

		.video-titleR {
			position: absolute;
			margin-top: -17rem;
			z-index: 3;
			color: #fff;
			padding-left: 1rem;
		}
	}

	.i-video-describe {
		margin-top: 0.89rem;
	}

	.video-img {
		height: 17.73rem;

		// background: #000;
		.mrImg {
			height: 2.73rem;
		}

		.showImg {
			height: 17.73rem;
		}

		img {
			height: 17.73rem;
		}
	}
}

.mr-videoR {
	text-align: center;
	line-height: 20.28rem;
}

//video主题色
.theme-color-img-orange {
	background-color: #fbb684;
}

.theme-color-img-green {
	background-color: #93ca8c;
}

.theme-color-img-turquoise {
	background-color: #66d1da;
}

.theme-color-img-yellow {
	background-color: #f7cb6e;
}

.theme-color-img-red {
	background-color: #e99090;
}

.theme-color-img-blue {
	background-color: #6eacfb;
}</style>
