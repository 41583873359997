<template>
	<div class="i-search">
		<div :class="{ 'search-nomal': true }">
			<div class="i-search-nomal">

				<div class="i-search-panel" :style="{
					borderRadius: unitData.shape == 'round' ? '1.88rem' : '0',
					position: unitData.isfixed ? 'fixed' : 'relative', 'z-index': unitData.isfixed ? 999 : 0
				}">
				<!-- {{ data }} -->
					<!-- justifyContent:unitData.inputAlign=='center'?'center':'' -->
					<div class="imgPj">
						<img v-if="unitData.img" :src="commenUrl + unitData.img" />
						<img v-else src="../../../assets/images/shopEdit/logo-img.png" />
					</div>
					<div class="pjContent" :style="{'text-align':(unitData.inputAlign=='center'?'center':'left')}">
						<div class="bigT" :class="'font-color-'+colorValue" :style="{ 'color': unitData.placeholderColor?unitData.placeholderColor:'' }">{{ unitData.placeholder }}</div>
						<div class="midT" :style="{ 'color': unitData.titleColor }">{{ unitData.title }}</div>
						<div class="smallT">评价编号{{data.evaluationNum||''}}</div>
					</div>
				</div>
			</div>
			<!-- <w-search v-model="keyword" :showAction="false" bgColor="#fff"
			:searchIconSize="36" searchIconColor="#a3a3a3" placeholderColor="#a3a3a3" :height="68"
			:disabled="disabled" :shape="shape" :placeholder="placeholder" :inputAlign="inputAlign" @tap="goPage()"></w-search> -->
		</div>
		<div class="search-fixed-h" v-if="unitData.isfixed"></div>
	</div>
</template>

<script>
export default {
	name: "search-panel",
	props: {
		unitData: {
			type: Object,
			default: {
				disabled: false,
				isfixed: false,
				shape: 'round',
				placeholder: '搜索商品',
				inputAlign: 'center'
			}
		},
		colorValue:{
			type:String,
			default(){
				return 'orange';
			}
		},
		data:{
			type:Object,
			default(){
				return {
					evaluationNum:''
				}
			}
		}
	},
	mounted() {
		// console.log(this.unitData)
	},
	data() {
		return {
			commenUrl: this.commenUrl,
		};
	},
	methods: {
		goPage() {
			uni.navigateTo({
				url: '/pages/commonPage/itemSearchPage/itemSearchPage?searchType=0'
			});
		}
	}


}
</script>

<style lang="less" scoped>
.i-search {
	margin:0 auto;
	margin-bottom: 10px;
	.i-search-panel {
		padding: 1.24rem 1.06rem;
		height: 6.73rem;
		display: flex;
		background-color: #FFFFFF;
		// background-image: url('../../../assets/images/shopEdit/pjBackground.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;

		.imgPj {
			width: 6.73rem;
			height: 6.73rem;
			background-color: #fff;

			img {
				width: 6.73rem;
				height: 6.73rem;
				border-radius: 6.73rem;
			}
		}

		.pjContent {
			margin-left: 0.6rem;

			div {
				margin-top: 0.22rem;
				// width: 21.02rem;
				// overflow: hidden;
				// word-break: break-all;
				// text-overflow: ellipsis;
				// white-space: nowrap;
				// color: #000;
			}

			.bigT {
				font-size: 1.38rem;
				height: 2.44rem;
				line-height: 2.44rem;
				font-weight: bold;
			}

			.midT {
				font-size: 1.15rem;
				height: 1.77rem;
				line-height: 1.77rem;
				// color: ;
			}

			.smallT {
				font-size: 1.06rem;
				height: 2rem;
				line-height: 2rem;
			}
		}
	}

	.search-nomal {
		width: 100%;
		// background: linear-gradient(0deg,#fbb684 1%,#ff8f3e);

	}

	.search-fixed {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1000;
	}

	.navbar-title {
		line-height: 4.88rem;
		margin-bottom: 1.15rem;
		font-size: 1.88rem;
		font-family: "PingFangSC-Medium";
		text-align: center;
		color: #FFFFFF;
	}

	.search-fixed-h {
		height: 9.31rem;
		// height: 298/0.22rem;
		// background-color:#fbb684;
	}
}</style>
