<template>
	<div class="i-notice">
		<div class="messageBox" :style="{'background-color':unitData.backgroundColor,'color':unitData.nameColor}">
			<notice :sendVal="unitData.list"></notice>
		</div>
	</div>
</template>

<script>
import notice from '../components/notice'
	export default {
		name:"notice-panel",
		components: {
			notice
		},
		props:{
			unitData:{
				type:Object,
				default:{
					list:[
						''
					],
					nameColor:'#000000',
					backgroundColor:'transparent',
					off:true,
					more:false,
					moreConfig:{
						type:'',
						href:''
					}
				}
			},
		},
		mounted(){
				// console.log(this.unitData)
		},
		data() {
			return {
			};
		},
		methods:{
			goPage(){
				uni.navigateTo({
				   url: '/pages/commonPage/itemSearchPage/itemSearchPage?noticeType=0'
				});
			}
		}
		
		
	}
</script>

<style lang="less">
.i-notice{
	margin:0 auto;
	.messageBox {
		overflow: hidden;
		padding: 0 1.06rem;
	}
}
</style>
