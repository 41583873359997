<template>
	<div class="goods-list">
		<div v-if="goodsData.length == 0" class="noGoods">
			暂无添加商品
		</div>
		<div v-else>
			<div class="goods-list-vertical" v-if="unitData.type == '1'">
				<div class="goods-item" v-for="(item, index) in goodsData" :key="index" @click="details(item.id)">
					<div class="goods-image"><img :src="commenUrl + item.productImgs.split(',')[0]" mode="aspectFill" /></div>
					<div class="goods-msg">
						<div class="uni-ellipsis goods-name">{{ item.productName }}</div>
						<div class="goods-price" v-if="item.price">
							¥<span class="bold">{{ item.price.toFixed(2) }}</span><span class="goods-measunit">元</span>
						</div>
					</div>
				</div>
			</div>
			<div class="goods-list-transverse" v-else>
				<div class="goods-item" v-for="(item, index) in goodsData" :key="index" @click="details(item.id)">
					<div class="goods-image"><img :src="commenUrl + item.productImgs.split(',')[0]" mode="aspectFill" /></div>
					<div class="goods-msg">
						<div>
							<div class="uni-ellipsis-double goods-name">{{ item.productName }}</div>
							<div class="uni-ellipsis goods-spec">{{ item.productSpecName }}</div>
						</div>
						<div class="goods-price" v-if="item.price">
							¥<span class="bold">{{ item.price.toFixed(2) }}</span><span class="goods-measunit">元</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <w-loadmore :status="unitData.status" bgColor="#fff" v-if="unitData.moreShow&&unitData.pageNo>1"></w-loadmore> -->
	</div>
</template>

<script>
export default {
	name: "goods-floor",
	props: {
		// 楼层信息
		unitData: {
			type: Object,
			default() {
				return {
					goodsFloor: [
						// {'productName':'黑龙江大葡萄味美汁多，好吃极了',price:199.99,titImgUrl:''}
					],//商品列表
					floorTit: '',//商品名称
					titType: 'txt',//显示样式(标题楼层/图片楼层)
					titImgUrl: '',//图片楼层图片路径
					titLine: '2',//名称显示行数(一行显示/两行显示)
					from: '1',
					goodsData: []
				};
			}
		},
		colorValue: {
			type: String,
			default() {
				return 'orange';
			}
		},
		goodsData: {
			type: Array,
			default() {
				return [
				];
			}
		},
	},
	data() {
		return {
			commenUrl: this.commenUrl,
		};
	},
	watch: {
	},
	computed: {
		selectD() {
			return this.unitData.goodsFloor
		},
		unitFrom() {
			return this.unitData.from
		},
	},
	mounted() {

	},
	methods: {
		priceSwitch(x) {
			let f = parseFloat(x);
			if (isNaN(f)) return false;
			f = Math.round(x * 100) / 100;
			let s = f.toString();
			let rs = s.indexOf('.');
			if (rs < 0) {
				rs = s.length;
				s += '.';
			}
			while (s.length < (rs + 1) + 2) {
				s += '0';
			}
			return s;
		},
		details(id){
			console.log(id)
		},
	},
}
</script>

<style lang="less" scoped>
.goods-list {
	margin:0 auto;
	margin-bottom: 10px;

	.uni-ellipsis-double {
		word-break: break-all;
		display: -webkit-box;
		overflow: hidden;
		line-height: 1.5;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.uni-ellipsis {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.goods-list-transverse {
		.goods-item {
			display: flex;
			padding: 14px;
			margin-bottom: 11px;
			background-color: #FFFFFF;

			.goods-image {
				width: 6.73rem;
				height: 6.73rem;
				margin-right: 14px;
				border-radius: 8px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.goods-msg {
				flex: 1;
				font-family: 'PingFangSC-Medium';
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			.goods-name {
				max-height: 48px;
				line-height: 24px;
				margin-bottom: 4px;
				overflow: hidden;
				font-size: 1.15rem;
				font-weight: 900;
				display: block;
				color: #333;
			}

			.goods-spec {
				margin-bottom: 13px;
				line-height: 20px;
				font-size: 14px;
				color: #999999;
			}
		}
	}

	.goods-list-vertical {
		overflow: hidden;

		.goods-item {
			float: left;
			width: 15rem;
			height: 21rem;
			margin-right: 1rem;
			margin-bottom: 1rem;
			border-radius: 8px;
			overflow: hidden;
			background-color: #FFFFFF;

			.goods-image {
				width: 15rem;
				height: 15rem;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.goods-msg {
				padding: 12px 12px 15px;
				font-family: 'PingFangSC-Medium';
			}

			.goods-name {
				margin-bottom: .8rem;
				line-height: 2rem;
				font-size: 1.15rem;
				font-weight: 900;
				display: block;
				color: #333;
			}
		}

		.goods-item:nth-child(2n) {
			margin-right: 0;
		}
	}

	.goods-price {
		line-height: 13px;
		font-size: 15px;
		color: #f24343;

		text {
			font-size: 12px;
		}

		text.goods-measunit {
			font-size: 12px;
		}

		.bold {
			font-weight: bold;
			font-size: 17px;
			font-family: "DIN Alternate"
		}
	}
	.noGoods{
		margin: 10px auto;
	}
}</style>