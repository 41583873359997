<template>
	<div class = "moduli">
		<div class="nav_parent">
			<div class="nav_parent_list" v-for="(item,index) in data" :key="index">
				<div class="w-skeleton-fillet">
					<div class="nav_parent_list_img">
						<img :src="commenUrl+item.imgUrl" mode="aspectFill" />
					</div>
					<div class="nav_parent_list_txt">
						{{item.title}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: "moduli",
		props:{
			// 数据获取
			data: {
				type: Array,
				default() {
					return [];
				}
			},
		},
	    data() {  
	        return {  
				commenUrl: this.commenUrl,
	        }  
	    },  
	    onLoad() {  
	
	    },  
	    methods: {  
			
	    }  
	}
</script>

<style lang="less" scoped>
	page{background: #F8F8F8;}
	.moduli{
		margin:0 auto;
		background: #FFFFFF;
		.nav_parent{
			width: 100%;
			.nav_parent_list{
				margin-right: 1.11rem;
				margin-bottom: 1.11rem;
				width:8.87rem;
				display: inline-block;
				font-size: 1.06rem;
				.nav_parent_list_img{
					padding-bottom: 1.06rem;
					img{
						background: #f2eada;
						width: 8.87rem;
						height: 8.87rem;
						border-radius: 0.55rem;
					}
				}
				.nav_parent_list_txt{
					height: 2.44rem;
					font-weight: 900;
					width:8.87rem;
					display: block;
					font-size: 1.15rem;
					line-height: 2.44rem;
					color: #555;
					overflow: hidden;
					span-align: center;
				}
			}
			::-webkit-scrollbar{//隐藏scroll滚动条
				display: none;
			    width: 0!important;
			    height: 0!important;
			    color: transparent;
			}
		}
	}
</style>
