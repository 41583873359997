<template>
  <div class="sznc-Login">
    <div class="login-bg" :class="{'login-bg1':loginType=='sub'}">
      <div class="login-head">
        <div class="title">{{headerTitle}}</div>
        <div class="change-btn">
          <el-button :type="loginType=='master'?'primary':''" @click="changeLoginType('master')">管理版</el-button>
          <el-button :type="loginType=='sub'?'primary':''" @click="changeLoginType('sub')">员工版</el-button>
        </div>
      </div>
      <div class="login-main">
        <div class="login-main-wrap">
          <div class="login-main-head">
            <div class="title">欢迎登录<span>{{headerTitle}}</span></div>
          </div>
          <div class="login-main-mid">
            <el-form :model="userPsdForm" ref="ruleFormRef" :rules="rules" v-if="loginType=='master'">
              <el-form-item label="" prop="username">
                <el-input v-model="userPsdForm.username" placeholder="请输入用户名称" clearable></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="userPsdForm.password" placeholder="请输入登录密码" type="password" clearable></el-input>
              </el-form-item>
              <div class="login-other-opts">
                <div><el-checkbox v-model="isRemember" size="small">记住密码</el-checkbox></div>
              </div>
              <el-form-item>
                <el-button type="primary" class="login-sub-btn"  @click="submitForm(ruleFormRef)">登录</el-button>
              </el-form-item>
            </el-form>
            <el-form :model="subUserPsdForm" ref="subruleFormRef" :rules="rules" v-else>
              <el-form-item label="" prop="username">
                <el-input v-model="subUserPsdForm.username" placeholder="请输入用户名称" clearable></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="subUserPsdForm.password" placeholder="请输入登录密码" type="password" clearable></el-input>
              </el-form-item>
              <div class="login-other-opts">
                <div><el-checkbox v-model="isRemember1" size="small">记住密码</el-checkbox></div>
              </div>
              <el-form-item>
                <el-button type="primary" class="login-sub-btn"  @click="submitForm(subruleFormRef)">登录</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCurrentInstance, reactive,ref } from "vue";
import { useRouter } from "vue-router";
import Md5 from 'js-md5';
let Base64 = require('js-base64').Base64;
export default {
  name: "szncLogin",
  setup() {
    const {proxy} = getCurrentInstance();
    const router= useRouter();
    const headerTitle = ref(process.env.VUE_APP_PUBLIC_TITLE || "");
    let userPsdForm = reactive({});
    let subUserPsdForm=reactive({});
    let rules=reactive({
      username:[{ required: true, message: '请输入用户名', trigger: 'blur' },],
      password:[{ required: true, message: '请输入密码', trigger: 'blur' },],
    });

    let loginType=ref("master");
    let isRemember = ref();
    let isRemember1 = ref();
    function init() {
       userPsdForm.username = localStorage.getItem("szncUsername")
          ? localStorage.getItem("szncUsername")
          : "";
        userPsdForm.password = localStorage.getItem("szncPassword")
          ? localStorage.getItem("szncPassword")
          : "";
        if (userPsdForm.password) {
          isRemember.value = true;
        }
      subUserPsdForm.username = localStorage.getItem("subszncUsername")
          ? localStorage.getItem("subszncUsername")
          : "";
        subUserPsdForm.password = localStorage.getItem("subszncPassword")
          ? localStorage.getItem("subszncPassword")
          : "";
        if (subUserPsdForm.password) {
          isRemember1.value = true;
        }
    }
    init();
    let ruleFormRef=ref();
    let subruleFormRef=ref();
    function submitForm(formEl) {
      formEl.validate((valid, fields) => {
        if (valid) {
          console.log('submit!')
        } else {
          console.log('error submit!', fields)
          return false
        }
        var password ="",basic ="",loginUrl="MASTER_LOGIN";
        if(loginType.value=="master"){
          loginUrl="MASTER_LOGIN";
          password = Md5(userPsdForm.password);
          basic = "Basic " + Base64.encode(userPsdForm.username + ":" + password);
        }else{
          loginUrl="SUB_LOGIN";
          console.log(subUserPsdForm.password)
          password = Md5(subUserPsdForm.password);
          basic = "Basic " + Base64.encode(subUserPsdForm.username + ":" + password);
        }
        proxy
        .$request({
          url:loginUrl,
          method: "post", //可以不传method 默认是post请求，
          // 允许携带证书
          xhrFields: {withCredentials: true},
          headers: {'Authorization': basic,},
        })
        .then((res) => {
          if(res.data.code=='000'){
            proxy.setCookie("userInfo", JSON.stringify(res.data.result));
            router.push('/');
            if(loginType.value=='master'){
              if (isRemember.value) {
                localStorage.setItem("szncUsername", userPsdForm.username);
                localStorage.setItem("szncPassword", userPsdForm.password);
              } else {
                localStorage.setItem("szncUsername", "");
                localStorage.setItem("szncPassword", "");
              }
            }else{
              if (isRemember1.value) {
                localStorage.setItem("subszncUsername", subUserPsdForm.username);
                localStorage.setItem("subszncPassword", subUserPsdForm.password);
              } else {
                localStorage.setItem("subszncUsername", "");
                localStorage.setItem("subszncPassword", "");
              }
            }
          }
        })
        .catch((err) => {
          console.log(err)
        });
      })
    }
    function changeLoginType(val){
      loginType.value=val;
    }
    return {
      isRemember,
      isRemember1,
      init,
      loginType,
      changeLoginType,
      submitForm,
      ruleFormRef,
      subruleFormRef,
      rules,
      userPsdForm,
      subUserPsdForm,
      headerTitle
    };
  },
};
</script>
<style scoped lang="less">
.sznc-Login {
  width: 100%;
  height: 100%;
  background-color: #e2e9f3;
  overflow: hidden;
  .login-bg {
    width: 100%;
    height: 100%;
    background: url(../../assets/images/user/login.jpg) no-repeat center left;
    background-size: 100% auto;
    position: relative;
  }
  .login-bg1{
    background-image: url(../../assets/images/user/login1.jpg);
  }
  .login-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 12.5rem 0;
    .title {
      font-size: 2.875rem;
      font-weight: bold;
      color: #0066ca;
    }
    .change-btn {
      .el-button {
        width: 7rem;
        height:3rem;
      }
    }
  }
  .login-main{
    position: absolute;
    top: 50%;
    right: 12.5rem;
    margin-top: -14rem;
    width: 30rem;
    height: 32rem;
    background: #fff;
    .login-main-wrap {
      padding: 3rem 4rem 0;
      .login-main-head {
        margin-bottom: 3rem;
        .title {
          line-height: 3rem;
          font-size: 2.1rem;
          color: #2c2c2c;
          span{
            margin-left: 1.3rem;
            font-size: 1.5rem;
            color: #9d9cba;
          }
        }
      }
      .login-main-mid{
        .login-sub-btn.el-button--primary {
          width: 100%;
          height: 3rem;
          font-size: 1.25rem;
        }
        .login-other-opts{
          height: 1.42rem;
          margin-bottom: 2.583rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1rem;
          color: #c7c7c7;
          .go-register a{
            color: #3e7fff;
            text-decoration: none;
          }
          :deep(.el-checkbox){
            color: #c7c7c7;
            .el-checkbox__input.is-checked+.el-checkbox__label{
              color:#3e7fff
            }
            .el-checkbox__input.is-checked .el-checkbox__inner{
              background-color: #3e7fff;
              border-color: #3e7fff
            }
            .el-checkbox__label{
              font-size: 1rem;
            }
            .el-checkbox__inner{
              width: 1rem;
              height: 1rem;
            }
            .el-checkbox__inner::after{
              left: 0.33rem;
              top: 0.083rem;
            }
          }
        }
      }
    }
  }
  :deep(.el-input) {
    height: 3rem;
    .el-input__wrapper{
      border-radius: 0.17rem;
      background-color: #f6f6fa;
      box-shadow:none
    }
    .el-input__inner {
      font-size: 1.17rem;
      color: #2c2c2c;
    }
  }
  .el-form-item {
    margin-bottom: 1.6rem;
    .el-form-item__content {
      line-height: 3rem;
    }
  }
  .el-button--primary {
    background: #3e7fff;
    border-radius: 0.17rem;
  }
}
</style>
<style>
@media only screen and (max-width: 1920px) {
	html {
		font-size: 16px;
	}
}
@media only screen and (max-width: 1440px) {
	html {
		font-size: 12px;
	}
}
</style>
