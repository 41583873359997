<template>
	<div class="i-textarea">
		<div :style="{'background-color':unitData.backgroundColor}">
			<!-- <div class="navbar-textarea">首页</div> -->
			<div v-html="imgSrcReplace(unitData.content)"></div>
			<!-- <w-textarea v-model="keyword" :showAction="false" bgColor="#fff"
			:textareaIconSize="36" textareaIconColor="#a3a3a3" placeholderColor="#a3a3a3" :height="68"
			:disabled="disabled" :shape="shape" :placeholder="placeholder" :inputAlign="inputAlign" @tap="goPage()"></w-textarea> -->
		</div>
	</div>
</template>

<script>
import VideoPlayer from '@/components/videoPlayer.vue';
	export default {
		name:"textarea-panel",
    	components:{VideoPlayer},
		props:{
			unitData:{
				type:Object,
				default:{
					name:'',
                    describe:'',
                    position:'left',
                    size:'16',
                    style:'14',
                    nameColor:'#000000',
                    describeColor:'#000000',
                    backgroundColor:'transparent',
                    more:false,
                    moreConfig:{
                        type:'',
                        
                    }
				}
			},
		},
		mounted(){
				// console.log(this.unitData)
		},
		data() {
			return {
				keyword:""
			};
		},
		methods:{
			goPage(){
				uni.navigateTo({
				   url: '/pages/commonPage/itemSearchPage/itemSearchPage?textareaType=0'
				});
			},
			imgSrcReplace(htmlstr) {
				let that = this;
				let regex3 = new RegExp(/<img [^>]*attr-src=['"]([^'>]+)[^>]*>/gi);
				if(!htmlstr){
					return '暂无配置'
				}
				let htmlstr1 = htmlstr.replace(regex3, function (match, capture) {
					console.log("capture:", capture);
					let tmp3 = capture.replace('"', "").replace(/[\\]/g, "");
					let tmp1 = tmp3.split("style=");
					let tmp2 = tmp3.split(" ");
					console.log(tmp2,"tmp3")
					// capture = capture;
					var newStr =
					"<video src=" +
					tmp2[0] +
					' controls="controls" alt="" style=' +
					tmp1[tmp1.length - 1] +
					'" ></video>';
					// console.log(newStr,"newStr")
					return newStr;
				});
				regex3 = null;
				return htmlstr1;
			},
		}
		
		
	}
</script>

<style lang="less">
.i-textarea{
	margin:0 auto;
	min-height: 3.33rem;
	img{
		width: 100%;
	}
	td{
		border: 1px solid #dadada;
		min-width: 2em;
		padding: 0.4em;
		-webkit-user-select: text;
		-moz-user-select: text;
		user-select: text;
		vertical-align: middle;
	}
	video{
		width: 31rem !important;
		height: 17.73rem !important;
		max-width: 31rem !important;
		max-height: 17.73rem !important;
	}
}
</style>
