<template>
  <div class='accountManage'>
    <component :data="projectData" :goodsData="goodsData" is='pageShow' disabled=true :list='listRight'
      :colorValue="themeList.find(e => e.id == projectData.remarks)?.colorValue">
    </component>
    <div id="container" style="width:300px;height:180px;display: none;"></div>
  </div>
</template>
<script>
import '../../assets/css/comCssW.less';
import pageShow from '@/components/customPage/pageCom/pageShow.vue'
// import AMapLoader from "@amap/amap-jsapi-loader";
import { reactive, ref, getCurrentInstance, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from 'vue-router';
export default {
  name: 'platInfo',
  components: { pageShow },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    let loadingflag = ref(false);
    let pageData = reactive({
    });
    onMounted(() => {
      window._AMapSecurityConfig = {
        securityJsCode: '2a519f539ec9179448c3ad0443446536',
      }
      // mapInit();
    });
    // 地图
    const mapInit = () => {
      AMapLoader.load({
        key: "4f7575a6a89daf43eb144e7ee71d7aea",
        version: "2.0",
        plugins: ["AMap.Geolocation"],
      }).then((AMap) => {
        var map = new AMap.Map('container');
        let geolocation = new AMap.Geolocation({
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          buttonOffset: new AMap.Pixel(10, 20),
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: true,
          panToLocation: true,
          //  定位按钮的排放位置,  RB表示右下
          buttonPosition: 'RB'
        });
        map.addControl(geolocation);
        geolocation.getCityInfo(function (status, result) {
          console.log(status)
          console.log(result)
          getcount(result)
        });
      }).catch((e) => {
        console.error(e);  //加载错误提示
      })
    }

    
    //获取商品信息
    const goodsData = ref([])
    const pageInit = e=>{
      if (e.length == 0) {
        goodsData.value = []
        return
      }
      proxy.$request({
        url: "TRADE_N_PRODUCT_PLATFINDPAGELIST",
        method: "post", //可以不传method 默认是post请求，
        data: {
          "productIds": e,
          "pageInfo": {
            "pageNo": 1,
            "pageSize": e.length
          },
          "productStatusType": '1',
        }
      }).then((res) => {
        let resp = res.data;
        if (resp.code == "000") {
          goodsData.value = resp.result.list
        } else {
          // this.hitAlert(res.data.msg);
        }
      }).catch((err) => {
        console.log(err)
      });
    }

    // 预览页面
    const projectData = ref({})
    const listRight = ref([])
    let getcount = (geo) => {//保存信息
      proxy
        .$request({
          url: "N_EVALUATION_GEOPRO_SCANLABELINFO",
          method: "post", //可以不传method 默认是post请求，
          data: {
            "areaProv": geo.province,
            "certNum": route.query.id,
            "sacType": "1"
          },
        })
        .then((res) => {
          if (res.data.code == "000") {
            console.log(res.data.result)
            listRight.value = res.data.result.currencyRetrospTemplate ? JSON.parse(res.data.result.currencyRetrospTemplate).list : [];
            projectData.value = res.data.result;
            //获取是否有商品列表
            let goodsIndex = listRight.value.findIndex(e => e.name == 'goodsFloor')
            if (goodsIndex > -1) {
              pageInit(listRight.value[goodsIndex].unitData.goodsFloor)
            }
            // router.push({path:'reviewPreview',query:{"id":res.data.result.id}})
          } else {
            proxy.$modal.info({
              title: '错误提示',
              content: res.data.msg
            });
          }
          loadingflag.value = false;
        })
        .catch(() => {
          loadingflag.value = false;
        });
    }
    //主题
    const themeList = ref([
      {
        "id": "1",
        "styleName": "能量太阳橙",
        "colorValue": "orange",
        "sort": 0,
        "remarks": null,
        "delFlag": "0"
      },
      {
        "id": "2",
        "styleName": "生机春绿色",
        "colorValue": "green",
        "sort": 1,
        "remarks": null,
        "delFlag": "0"
      },
      {
        "id": "3",
        "styleName": "灵动水蓝色",
        "colorValue": "turquoise",
        "sort": 2,
        "remarks": null,
        "delFlag": "0"
      },
      {
        "id": "4",
        "styleName": "开朗金黄色",
        "colorValue": "yellow",
        "sort": 3,
        "remarks": null,
        "delFlag": "0"
      },
      {
        "id": "5",
        "styleName": "魅力天空蓝",
        "colorValue": "blue",
        "sort": 4,
        "remarks": null,
        "delFlag": "0"
      },
      {
        "id": "6",
        "styleName": "温馨浅绯色",
        "colorValue": "red",
        "sort": 5,
        "remarks": null,
        "delFlag": "0"
      }
    ])
    // const getInfo = (id)=>{
    //     proxy.$request({
    //         url: 'EVALUATION_INFO',
    //         method: "post",
    //         data: {
    //             "id": id
    //         },
    //     }).then((res) => {
    //         if (res.data.code == "000") {
    //             // listRight.value = this.progectList[index].data
    //             listRight.value = res.data.result.currencyRetrospTemplate?JSON.parse(res.data.result.currencyRetrospTemplate).list:[];
    //             projectData.value = res.data.result;
    //         } else {
    //         this.$alert(res.data.msg || "数据请求失败，请重试", "提示", {
    //             confirmButtonText: "确定",
    //             customClass: "sellPopCss",
    //         });
    //         }
    //     });
    // }
    // getInfo(route.query.id)
    return {
      ...toRefs(pageData),
      themeList,
      projectData,
      listRight,
      goodsData
    };
  },

};

</script>
<style scoped lang="less">
.addchildrenserv {
  height: 100%;
}
</style>