<template>
    <video ref="videoPlayer" class="video-js">
      <source :src="src" type="application/x-mpegURL">
    </video>
</template>

<script>

import {shallowRef, ref,onMounted,onUnmounted, reactive,watch} from 'vue'
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
export default {
    name: "VideoPlayer",
    props: {
        // options: {
        //     type: Object,
        //     default() {
        //         return {
                  
        //         };
        //     }
        // },
        src:{
          type:String,
          default:""
        }
    },
    setup(props){
      let player = shallowRef(null);
      let videoPlayer=ref();
      let options=reactive({
        title: 'Oceans',
        autoplay: false, // 设置自动播放
        muted: false, // 设置了它为true，才可实现自动播放,同时视频也被静音 （Chrome66及以上版本，禁止音视频的自动播放）
        preload: 'auto', // 预加载
        controls: true, // 显示播放的控件
        playbackRates: [0.5, 1, 1.5, 2, 3, 4],
        "sources": [{
            // src: 'https://jf360videos.peopletech.cn/production/464f2fe20b1211ee83bef3d9bb3dfecd_1_video.mp4',
            src: props.src,
            // type: 'video/mp4'
        }],
        notSupportedMessage:"此视频无法播放，请稍后再试"
      })
      watch(()=>props.src,(newValue,oldValue)=>{
        // console.log()
        if(newValue){
          options.sources[0].src = props.src
            player = videojs(videoPlayer.value,options, function onPlayerReady() {
          });
        }
			})
      onMounted(()=>{
        player = videojs(videoPlayer.value,options, function onPlayerReady() {
        });
        console.log(player,'初始化')
      });
      onUnmounted(() => {
        //离开页面时销毁video
        console.log(player,'销毁')
        if (player) {
          player.dispose()
        }
      });
      return {
        player,
        videoPlayer,
        options,
      }
    }
}
</script>
<style scoped>
.video-js{
  width: 100%;
  height: 100%;
}
</style>
