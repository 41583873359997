<template>
	<div class="imgViewShow" :class="unitData.type=='1'?'swpBanner':'imgBanner'" :style="{
		borderRadius: unitData.borderRadius+'px',
		margin:unitData.margin}"
	>
		<!-- <w-swiper :list="unitData.imgList" name="url" duration="500" circular indicator-pos="bottomRight"
		 mode="number" :height="unitData.height" :borderRadius="unitData.borderRadius" @click="swiperClick"></w-swiper> -->
		 <el-carousel :height="182" :class="['theme-color-img-'+colorValue,{'zIndex_0':classShow}]" v-if="unitData.type=='1'" indicator-position="none" :style="{
		borderRadius: unitData.borderRadius+'px',
		height: '17.73rem',
		'margin-bottom':'10px'}" arrow="never">
			<el-carousel-item v-for="(item,index) in unitData.imgList" :key="index">
				<div class="relative">
					<img v-if="item.image" :src="commenUrl+item.image" alt="" @click="toHref(item.urlGo)">
					<div class="car-title">{{ item.title }}</div>
				</div>
			</el-carousel-item>
		</el-carousel>
		<div v-if="unitData.type=='2'" class="imgSC">
			<div class="relative" v-for="(item,index) in unitData.imgList" :key="index" alt="">
				<div class="showImg" v-if="item.image">
					<img :src="commenUrl+item.image" alt="" @click="toHref(item.urlGo)">
					<div class="img-title">{{ item.title }}</div>
				</div>
				<div class="mrImg" :class="['theme-color-img-'+colorValue]" v-else>
					<img src="../../../assets/images/shopEdit/image.png" alt="">
				</div>
			</div>
				
		</div>
		
	</div>
</template>

<script>
	export default {
		name:"img",
		props: {
			unitData:{
				type:Object,
				default:{
					bgColor:"#ff8f3e",
					height:182,
					borderRadius:20,
					imgList:[],
					margin:'0 0 12px'
				}
			},
			colorValue:{
				type:String,
				default(){
					return 'orange';
				}
			},
			classShow:{
				type:Boolean,
				default () {
					return false;
				}
			},
		},
		data() {
			return {
				commenUrl:this.commenUrl,
			};
		},
		methods:{
			swiperClick(index){
				// console.log(index)
				// console.log(this.imgList[index])
			},
			toHref(e){
				if(e){
					window.location = e
				}
				
			}
		}
	}
</script>
<style lang="less">
.imgViewShow{
	margin:0 auto;
	// margin-bottom: 10px;
	.el-carousel{
		// width: 347px;
		height: 17.73rem;
		.el-carousel__container{
			height: 17.73rem;
		}
		img{
			width: 100%;
			height:17.73rem;
		}
	}
	.carouselImg{

	}
	.imgSC{
		// height:17.73rem;
		.showImg{
			img{
				width: 100%;
				height:17.73rem;
				margin-bottom: 10px;
			}
		}
		.mrImg{
			width: 100%;
			height:17.73rem;
			line-height: 20.28rem;
			text-align: center;
			margin-bottom: 15px;
		}
	}
	.relative{
		position: relative;
	}
	.img-title{
		position: absolute;
		margin-top: -3.4rem;
		padding-left: 1rem;
		font-size: 1.6rem;
	}
	.car-title{
		position: absolute;
		margin-top: -2.8rem;
		padding-left: 1rem;
		font-size: 1.6rem;
	}
}
.mr-imgR{
	text-align: center;
	line-height: 20.28rem;
}

//img主题色
.theme-color-img-orange{
	background-color:#fbb684;
}
.theme-color-img-green{
	background-color:#93ca8c;
}
.theme-color-img-turquoise{
	background-color:#66d1da;
}
.theme-color-img-yellow{
	background-color:#f7cb6e;
}
.theme-color-img-red{
	background-color:#e99090;
}
.theme-color-img-blue{
	background-color:#6eacfb;
}
</style>
