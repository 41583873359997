<template>
	<div class="i-separate">
		<div :class="{ 'separate-nomal': true }" :style="{ 'background-color': unitData.backgroundColor }">
			<!-- 线条分隔 -->
			<div class="i-separate-name" v-if="unitData.showContent == 'solid'"
				:style="{ 'border-top-style': unitData.border, 'border-top-color': unitData.borderColor, 'margin': unitData.padding ? '0 1.66rem' : 0 }">
			</div>
			<!-- 文字分隔 -->
			<div class="i-separate-name" v-if="unitData.showContent == 'char'"
				:style="{'margin': unitData.padding ? '0 1.66rem' : 0 }">
				<!-- 左右线条填充 -->
				<div v-if="unitData.fill=='solid'">
					<span style="white-space:pre"> </span><span class="line" :style="{ 'border-top-style': unitData.border, 'border-top-color': unitData.borderColor, 'width': unitData.padding ? '9.35rem':'10.67rem' }"></span>
					<span style="white-space:pre"> </span><span class="txt" :style="{ 'font-size': unitData.charSize*0.088+'rem', 'color': unitData.charColor }">{{ unitData.describe }}</span>
					<span style="white-space:pre"> </span><span class="line" :style="{ 'border-top-style': unitData.border, 'border-top-color': unitData.borderColor, 'width': unitData.padding ? '9.35rem':'10.67rem' }"></span>
				</div>
				<!-- 空白填充 -->
				<div v-else class="noFill" :style="{ 'font-size': unitData.charSize*0.088+'rem', 'color': unitData.charColor }">
					{{ unitData.describe }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "separate-panel",
	props: {
		unitData: {
			type: Object,
			default: {
				name: '',
				describe: '',
				position: 'left',
				size: '16',
				style: '14',
				nameColor: '#000000',
				describeColor: '#000000',
				backgroundColor: 'transparent',
				more: false,
				moreConfig: {
					type: '',

				}
			}
		},
	},
	mounted() {
		// console.log(this.unitData)
	},
	data() {
		return {
			keyword: ""
		};
	},
	methods: {
		goPage() {
			uni.navigateTo({
				url: '/pages/commonPage/itemSearchPage/itemSearchPage?separateType=0'
			});
		}
	}


}
</script>

<style lang="less">
.i-separate {
	margin:0 auto;
	// margin-bottom: 1.77rem;

	.separate-nomal {
		padding: 0.67rem 0;
	}

	.i-separate-name {
		border-top: 0.11rem #000;
	}

	.line {
		display: inline-block;
		padding-top: 0.33rem;
		width: 14.63rem;
		border-top: 0.11rem solid #666666;
	}

	.txt {
		display: inline-block;
		color: #333333;
		font-size: 1.15rem;
		vertical-align: middle;
		width: 8.98rem;
		text-align: center;
		height: 1.77rem;
		line-height: 1.77rem;
	}

	.noFill{
		text-align: center;
		height: 1.77rem;
		line-height: 1.77rem;
		font-size: 1.15rem;
		color: #333333;
	}
}
</style>
