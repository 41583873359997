
import {request} from "../../../public/js/request"
import {axiosFileDownload} from "../../../public/js/axiosFileDownloader"
import * as tool from "../../../public/js/util"
import {getCookie,setCookie,checkCookie,delCookie} from "../../../public/js/cookie"
// import * as echarts from 'echarts'
//import mitt from "mitt"
export default {
    install(app){
        app.config.globalProperties.$request=request
        app.config.globalProperties.$axiosFileDownload=axiosFileDownload
        app.config.globalProperties.tool=tool
        app.config.globalProperties.getCookie=getCookie
        app.config.globalProperties.setCookie=setCookie
        app.config.globalProperties.checkCookie=checkCookie
        app.config.globalProperties.delCookie=delCookie
        app.config.globalProperties.myfilter=()=>{
            return 666
        }
        var localObj = window.location;
        let commenUrl = process.env.VUE_APP_PUBLIC_PATH
        if(!commenUrl){
            commenUrl = localObj.protocol + "//" + localObj.host+process.env.VUE_APP_PUBLIC_NAME+'/sr/';
        }
        app.config.globalProperties.commenUrl = commenUrl
        let apiUrl = process.env.VUE_APP_BASE_API
        if(!apiUrl){
            apiUrl = localObj.protocol + "//" + localObj.host+process.env.VUE_APP_PUBLIC_NAME;
        }
        app.config.globalProperties.apiUrl = apiUrl
        app.config.globalProperties.UPLOADIMG = apiUrl+"/system/w/uploadImg"
        app.config.globalProperties.UPLOADAUD = apiUrl+"/system/w/uploadAud"
        app.config.globalProperties.glob="global"
        // app.config.globalProperties.$echarts=echarts
        let environment = process.env.VUE_APP_PUBLIC_ENVIRONMENT
        app.config.globalProperties.environment = environment
            
        //app.component("组件name",组件)
        //app.config.globalProperties.$bus=new mitt()

    }
}
