let Api = {
    "ORDER_FINDPAGELIST":"/trade/w/order/findPageList",//分页查询订单列表
    "REFUNDREASONMAGE_FINDALLLIST":"/trade/w/RefundReasonMage/findAllList",// 查询取消/售后原因管理列表
    "LOGISTICSCOMPMAGE_FINDLIST":"/trade/w/LogisticsCompMage/findList",// 查询所有物流公司列表
	"ORDER_FINDSHOPORDERINFO": "/trade/w/order/findShopOrderInfo", //查询店铺订商单详细信息
    "ORDER_EXPORT":"/trade/w/order/exportOrder",//导出订单列表
    "LOGISTICSCOMPMAGE_FINDPAGELIST": "/trade/w/LogisticsCompMage/findPageList", //分页查询物流公司列表
    "LOGISTICSCOMPMAGE_DEL": "/trade/w/LogisticsCompMage/del", //逻辑删除物流公司
    "LOGISTICSCOMPMAGE_SAVEORUPDATE":"/trade/w/LogisticsCompMage/saveOrUpdate",//新增或修改物流公司
    "LOGISTICSCOMPMAGE_EXPORT":"/trade/w/LogisticsCompMage/exportCom",//导出物流公司列表
    "ORDERREFUNDAPPL_FINDSHOPPAGELIST":"/trade/w/OrderRefundAppl/findShopPageList",//分页查退款申请列表
	"ORDERREFUNDAPPL_FINDRIEINFO": "/trade/w/OrderRefundAppl/findrieInfo", //查询退款详细信息
    "ORDERREFUNDAPPL_EXPORT":"/trade/w/OrderRefundAppl/exportOrderRefundAppl",//导出退款订单列表
    //期限
    "DEADLINEMAGE_LIST":"/trade/w/DeadLineMage/list",//查询期限管理列表
    "DEADLINEMAGE_UPDATE":"/trade/w/DeadLineMage/update",//编辑期限管理
    //服务费
    "SERVICECHARGEMAGE_PAGE":"/trade/w/ServiceChargeMage/page",//获取服务费管理分页列表
    "SERVICECHARGEMAGE_UPDATE":"/trade/w/ServiceChargeMage/update",//配置服务费管理
    //订单取消/售后原因
    "REFUNDREASONMAGE_PAGE":"/trade/w/RefundReasonMage/page",//分页查询取消/售后原因管理列表
    "REFUNDREASONMAGE_GET":"/trade/w/RefundReasonMage/get",//查询取消/售后原因管理详情
    "REFUNDREASONMAGE_LOGICDEL":"/trade/w/RefundReasonMage/logicDel",// 删除取消/售后原因管理原因
    "REFUNDREASONMAGE_SAVEORUPDATE":"/trade/w/RefundReasonMage/saveOrUpdate",// 保存取消/售后原因管理



}
module.exports = Api