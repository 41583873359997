<template>
	<div class="banner" :class="unitData.type=='1'?'swpBanner':'imgBanner'" >
		<div class="enterpriseTitle">生产经营单位信息</div>
		 <el-carousel :height="unitData.height" :class="[{'zIndex_0':classShow}]" v-if="unitData.type=='1'" indicator-position="none" :style="{
		borderRadius: unitData.borderRadius*0.088+'rem',
		height: unitData.height*0.088 + 'rem',
		margin:unitData.margin}" arrow="never">
			<el-carousel-item v-for="(item,index) in unitData.bannerList" :key="index">
				<div class="enterprise">
					<div class="imgPj">
						<img v-if="item.image" :src="commenUrl + item.image" />
						<img v-else src="../../../assets/images/shopEdit/banner/qy.png" />
					</div>
					<div class="pjContent">
						<div class="bigT two_hidden">{{item.title}}</div>
						<div class="smallT two_hidden">{{item.urlGo}}</div>
						<!-- <div class="smallT">www.sd18y.com</div> -->
					</div>
				</div>
			</el-carousel-item>
		</el-carousel>
		<div v-if="unitData.type=='2'" class="imgSC" v-for="(item,index) in unitData.bannerList" :key="index" >
			<div class="enterprise">
				<div class="imgPj">
					<img v-if="item.image" :src="commenUrl + item.image" />
					<img v-else src="../../../assets/images/shopEdit/banner/qy.png" />
				</div>
				<div class="pjContent">
						<div class="bigT two_hidden">{{item.title}}</div>
						<div class="smallT two_hidden">{{item.urlGo}}</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		name:"banner",
		props: {
			unitData:{
				type:Object,
				default:{
					bgColor:"#ff8f3e",
					height:90,
					borderRadius:20,
					bannerList:[],
					margin:'0 0 1.06rem'
				}
			},
			colorValue:{
				type:String,
				default(){
					return 'orange';
				}
			},
			classShow:{
				type:Boolean,
				default () {
					return false;
				}
			},
		},
		data() {
			return {
				commenUrl:this.commenUrl,
			};
		},
		methods:{
			swiperClick(index){
				// console.log(index)
				// console.log(this.bannerList[index])
			}
		}
	}
</script>

<style lang="less" scoped>
.banner{
	margin:0 auto 10px;
	background-color: #fff;
	padding: 1.06rem 0;
	.enterpriseTitle{
		padding: 0 1.06rem 1.66rem;
		width: 11.06rem;
		height: 1.77rem;
		font-family: PingFangSC-Medium;
		font-size: 1.15rem;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0;
		color: #000000;
	}
	.enterprise{
		width: 29rem;
		height: 9.98rem;
		display: flex;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		padding: 0 1.06rem 1.06rem;
		.imgPj {
			width: 9.98rem;
			height: 9.98rem;
			border-radius: 0.89rem;
			img {
				width: 9.98rem;
				height: 9.98rem;
				border-radius: 0.89rem;
			}
		}

		.pjContent {
			margin-left: 1.06rem;
			padding: 0.7rem 0 1rem;
			div {
				font-family: PingFangSC-Regular;
				font-size: 1.15rem;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2.06rem;
				// height: 2.66rem;
				letter-spacing: 0;
				color: #000000;
				width: 17.8rem;
			}
			.bigT{
				// overflow: hidden;
				// word-break: break-all;
				// text-overflow: ellipsis;
				// white-space: nowrap;
			}
			.smallT{
				margin-top: 0.6rem;
				color: #666;
				font-size: 1.05rem;
				line-height: 1.86rem;
			}
		}
	}
}
.el-carousel{
	width: 38.46rem;
	height: 9.98rem;
	padding: 0 0 1.06rem;
}
/deep/.el-carousel__container{
	height: 9.98rem;
}
// .el-carousel__item img {
// 	width: 38.46rem;
// 	height: 14.63rem;
// }
.imgSC{
	width: 37.91rem;
    // height: 14.63rem;
	// margin-bottom: 1.66rem;
	// background-color: #ff8f3e;
	img{
		width: 37.91rem;
		height: 14.63rem;
		margin-bottom: 1.66rem;
    	// background-color: #ff8f3e;
	}
	img:last-child{
		margin-bottom: 0;
	}
}

.imgBanner{
	height: inherit;
}
.swpBanner{
	height: 13.97rem;
}
//banner主题色
.theme-color-banner-orange{
	background-color:#fbb684;
}
.theme-color-banner-green{ 
	background-color:#93ca8c;
}
.theme-color-banner-turquoise{
	background-color:#66d1da;
}
.theme-color-banner-yellow{
	background-color:#f7cb6e;
}
.theme-color-banner-red{
	background-color:#e99090;
}
.theme-color-banner-blue{
	background-color:#6eacfb;
}
.zIndex_0{
	z-index: 0;
}

</style>
