let Api = {
    "PLATMANAGE_FINDPAGELIST":"/w/PlatManage/findPageList",//平台列表
    "PLATMANAGE_SAVEORUPDATE":"/w/PlatManage/saveOrUpdate",//新增或修改平台信息
    "PLATMANAGE_FINDINFO":"/w/PlatManage/findInfo",//查询平台详细信息
    "PLATMANAGE_CONFIG_INFO":"/w/PlatManage/config/info",//平台配置详情(入参：平台id)
    "PLATMANAGE_CONFIG_SYNC":"/w/PlatManage/config/sync",//平台信息同步
    "PLATMENU_TREES":"/w/PlatMenu/tree",//获取全部菜单树
    "PLATMANAGE_AUTH_INFO":"/w/PlatManage/auth/info",//平台认证详情
    "PLATMANAGE_AUTH_SUBMIT":"/w/PlatManage/auth/submit",//平台认证提交
    "PLATMANAGE_AUTH_TEMP":"/w/PlatManage/auth/temp",//平台认证暂存
    "MENU_UPDATE":"/w/PlatMenu/update",//修改菜单
    "MENU_INSERT":"/w/PlatMenu/insert",//新增菜单
    "PLATMENU_DELETE":"/w/PlatMenu/delete",//删除菜单
    
}
module.exports = Api