export default [{
    path: 'evidenceRecord',
    name: {list:['品质评价','合格证','合格证管理','出证记录']},
    component: () => import('@/views/conformity/evidenceRecord.vue')
},
{
    path: 'codeRecord',
    name: {list:['品质评价','合格证','合格证管理','扫码记录']},
    component: () => import('@/views/conformity/codeRecord.vue')
},
{
    path: 'rapidCertification',
    name: {list:['品质评价','合格证','合格证管理','快速出证']},
    component: () => import('@/views/conformity/rapidCertification.vue')
},
{
    path: 'appliLabelList',
    name: {list:['品质评价','评价','评价标签','标签申请']},
    component: () => import('@/views/evaluationLabel/appliLabelList.vue')
},
{
    path: 'appliLabelRecd',
    name: {list:['品质评价','评价','评价标签','申请记录']},
    component: () => import('@/views/evaluationLabel/appliLabelRecd.vue')
},
{
    path: 'assessmentList',
    name: {list:['品质评价','评价','评价管理','评价列表']},
    component: () => import('@/views/transaction/assessment/list.vue')
},{
    path: 'assessmentAdd',
    name: {list:['品质评价','评价','评价管理','评价列表','添加/编辑项目信息'],title:'添加/编辑项目信息',show:true},
    component: () => import('@/views/transaction/assessment/detail.vue')
},{
    path: 'plant',
    name: {list:['品质评价','评价','评价管理','评价列表','添加/编辑项目信息'],title:'添加/编辑项目信息',show:true},
    component: () => import('@/views/plant/plant.vue')
}
]