let Api = {
	"AUTH_LIST": "/w/user/viewMenu/list", //菜单列表
	"MENUSERVICE_LIST": "/w/user/menuService/list", //菜单列表
	"SYS_UPLOADIMG": "/w/uploadImg", //上传图片
	'UPLOADAUD': '/w/uploadAud', //上传视频
	"MASTER_LOGIN": "/login?ltype=plat&utype=master", //主账户登录
	"SUB_LOGIN": "/login?ltype=plat&utype=sub", //子账户登录
	"W_PLATMEMB_FINDMEMBPAGELIST": "/w/platMemb/findMembPageList", //分页查询商家列表
	"W_PLATMEMB_PLATFINDMEMBINFO": "/w/platMemb/platFindMembInfo", //查询商家详细信息
	"W_PLATMEMB_UPDATEENABLESTATUS": "/w/platMemb/updateEnableStatus", //修改启用禁用状态
	"W_PLATMEMB_RESETPWD": "/w/platMemb/resetPwd", //重置密码
	"W_PLATMEMBAUTH_FINDPAGELIST": "/w/platMembAuth/findPageList", //分页查询商家认证列表信息
	"W_PLATMEMBAUTH_GETDETAIL": "/w/platMembAuth/getDetail", //分页查询商家认证列表信息
	"W_PLATMEMBAUTH_AUTHENTPDATAINFO": "/w/platMembAuth/authEntpDataInfo", //企业认证审核处理
	"W_PLATMEMB_FINDMEMBOPENSERVER": "/w/platMemb/findMembOpenServer", //查询用户已开通服务列表
	"W_PLATMEMB_MEMBOPENSERVERUPDATESTATUS": "/w/platMemb/membOpenServerUpdateStatus", //修改已开通服务状态
	"W_PLATMEMB_CONFIGDIFAMUPDATE": "/w/platMemb/configDifamUpdate", //修改数字基地配置信息
	"W_PLATMEMB_FINDMEMBVERIFIEDPAGELIST": "/w/platMemb/findMembVerifiedPageList", //分页查询服务列表
  
  
	"LOGIN": "/login?ltype=plat&utype=master",//登录
	"LOGOUT": "/w/logout",//登出
	"SYSTEM_W_MEMB_USER_UPDPWD": "/w/memb/user/updPwd", //修改登录密码
	"SYSTEM_W_MEMB_SENDFORREGTEL": "/w/memb/sendForRegTel", //手机号验证码发送
	"SYSTEM_W_MEMB_SAVEREGMOBILE": "/w/memb/saveRegMobile", //保存注册手机号
  "SYSTEM_W_CATEGORYMAGE_FINDCOMMMAGELIST": "/w/categoryMage/findCommMageList", //改版后的品类,品种选择,精准搜索到品种
	"SYSTEM_W_PLATINFOMAGE_FINDINFO": "/w/PlatInfoMage/findInfo", //获取平台信息
	"SYSTEM_W_PLATINFOMAGE_SAVEORUPDATE": "/w/PlatInfoMage/saveOrUpdate", //新增或修改
	"SYSTEM_W_CATEGORYMAGE_FINDCOMMMAGETREE": "/w/categoryMage/findCommMageTree", //查询基础品类树
	
	
	"SYSTEM_W_PLATMEMBAUTH_EXPORTMEMBAUTH": "/w/platMembAuth/exportMembAuth", //导出商家认证信息列表
	"W_PLATMEMB_EXPORTMEMB": "/w/platMemb/exportMemb", //分页查询商家列表导出
	"W_ENTPSERVICE_FINDPAGELIST": "/w/EntpService/findPageList", //分页查询子服务列表
	"W_ENTPSERVICE_FINDLIST": "/w/EntpService/findList", //查询子服务列表
	"W_ENTPSERVICE_FINDINFO": "/w/EntpService/findInfo", //查询子服务详情
	"W_ENTPSERVICE_DEL": "/w/EntpService/del", //删除子服务
	"W_ENTPSERVICE_SAVEORUPDATE": "/w/EntpService/saveOrUpdate", //新增子服务
	"W_ENTPSERVICE_ENTPMENULIST": "/w/EntpService/entpMenuList", //企业菜单列表
	"W_ENTPAUTH_UPDATEBANKACCOUNT": "/w/entpAuth/updateBankAccount", //修改结算账户
	"SYSTEM_MEMB_CHECKMOBILEEXIST": "/memb/checkMobileExist", //验证手机号是否已经存在
	"SYSTEM_MOBILE_VALCODE_CREATE": "/mobile/valCode/create", //注册获取验证码
  
  
}
module.exports = Api