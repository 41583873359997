import {createStore} from "vuex"

//创建store实例
const store=createStore({
    state(){
        return {
            address:[],
            catch_components: [],
            formList:[],
            path:'',
            pageInfo:{},
            mapData:{
                data:[],
                mouseLocation: [0, 0],
                zoom: 13,
                range: [0, 0],
            },
            mapMes:{}
        }
    },
    mutations:{
        setAddress: (state, data) => {
            state.address = data
        },
        GET_CATCHE_COMPONENTS (state, data) {
            state.catch_components = data
        },
        setFormList (state, data) {
            state.formList = data
        },
        setPageInfo (state, data) {
            console.log(data)
            state.pageInfo = data
        },
        pathse (state, data) {
            state.path = data
        },
        mapDataChange (state, data) {
            state.mapData.data = data
        },
        mapConfigChange (state, data) {
            state.mapData.mouseLocation = [Number(data.mouseLocation[0].toFixed(5)),Number(data.mouseLocation[1].toFixed(5))]
            state.mapData.zoom = data.zoom
            state.mapData.range = data.range
        },
        mapMesChange (state, data) {
            state.mapMes = data
        }
    }
})
export default store;