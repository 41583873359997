let Api = {
	"EMPLOYEE_FINDPAGELIST": "/system/w/employeeInfo/findPageList", //用户列表
    "EMPLOYEE_SAVEORUPDATE": "/system/w/employeeInfo/saveOrUpdate", //用户新增
    "ROLE_GETLIST": "/system/w/admin/role/findAllRoleList", //角色列表
    "ROLE_DEL": "/system/w/employeeInfo/del", //角色删除
    "ROLE_FINDINFO": "/system/w/employeeInfo/findInfo", //角色查询
    "EMPLOYEEINFO_RESETPWD": "/system/w/employeeInfo/resetPwd", //重置密码
    
    

    "ROLE_FINDROLEPAGE": "/system/w/admin/role/findRolePage", // 角色管理列表
	"ROLE_DELROLEINFO": "/system/w/admin/role/delRoleInfo", //删除角色信息
	"ROLE_SAVEORUPD": "/system/w/admin/role/saveOrUpd", // 新增或者修改角色信息
	"ROLE_FINDALLROLELIST": "/system/w/admin/role/findAllRoleList", //查询当前平台的角色列表
	"ROLE_ROLEMEMBLIST": "/system/w/admin/role/roleMembList", // 查看角色成员
	"ROLE_MENU_LIST": "/system/w/admin/role/menu/list", //获取菜单列表(新增修改角色菜单列表)
	"ROLE_GETDETAIL": "/system/w/admin/role/getDetail", //根据ID查询角色详细信息
    
	
	
	
}
module.exports = Api