export default [{
    path: 'platList',
    name: {list:['产业中心','平台','平台管理','平台列表']},
    component: () => import('@/views/platPage/platList.vue')
},
{
    path: 'platAdd',
    name: {list:['产业中心','平台','平台管理','平台列表'],title:'添加/编辑平台信息'},
    component: () => import('@/views/platPage/platAdd.vue')
},
{
    path: 'platAuthAllocation',
    name: {list:['产业中心','平台','平台管理','平台权限'],title:'权限配置'},
    component: () => import('@/views/platPage/platAuthAllocation.vue')
},
{
    path: 'platAuthList',
    name: {list:['产业中心','平台','平台管理','平台权限']},
    component: () => import('@/views/platPage/platAuthList.vue')
},
{
    path: 'platRenzList',
    name: {list:['产业中心','平台','平台管理','平台认证']},
    component: () => import('@/views/platPage/platRenzList.vue')
},
{
    path: 'platSettle',
    name: {list:['产业中心','平台','平台管理','平台认证'],title:'立即认证'},
    component: () => import('@/views/platPage/settleEdit.vue')
},
{
    path: 'platSettleDetails',
    name: {list:['产业中心','平台','平台管理','平台认证'],title:'认证详情'},
    component: () => import('@/views/platPage/settleDetail.vue')
},
{
    path: 'platMenu',
    name: {list:['产业中心','平台','菜单管理','平台菜单']},
    component: () => import('@/views/platPage/platMenu.vue')
},
]