export default [{
    path: 'role',
    name: {list:['系统设置','设置','组织架构','角色管理']},
    component: () => import('@/views/setUp/role.vue')
},
{
    path: 'roleEdit',
    name: {list:['系统设置','设置','组织架构','角色管理','添加角色'],title:'添加角色'},
    component: () => import('@/views/setUp/roleEdit.vue')
},
{
    path: 'staff',
    name: {list:['系统设置','设置','组织架构','员工管理']},
    component: () => import('@/views/setUp/staff.vue')
},
{
    path: 'staffEdit',
    name: {list:['系统设置','设置','组织架构','员工管理','添加员工'],title:'添加员工'},
    component: () => import('@/views/setUp/staffEdit.vue')
},
{
    path: 'termSet',
    name: {list:['系统设置','设置','系统配置','期限设置',]},
    component: () => import('@/views/setUp/termSet.vue')
},
{
    path: 'security',
    name: {list:['系统设置','设置','基本信息','账户安全'],title:'账户安全'},
    component: () => import('@/views/setUp/security.vue')
},
{
    path: 'platInfoManage',
    name: {list:['系统设置','设置','基本信息','平台信息'],title:'平台信息'},
    component: () => import('@/views/setUp/platInfoManage.vue')
}
]