<template>
  <div class="topHeader">
    <div class="left">{{ headerTitle }}</div>
    <div class="midList">
      <div
        class="midLi"
        :class="selectIndex == menuIndex ? 'active' : ''"
        @click="selectMenu(menuLi, menuIndex)"
        v-for="(menuLi, menuIndex) in menuList"
        :key="menuLi.id"
      >
        <div>{{ menuLi.name }}</div>
        <div class="line_w"></div>
      </div>
    </div>
    <div class="right">
      <el-icon :size="20">
        <UserFilled />
      </el-icon>
      <span class="userName">{{ userName.split("_")[0] }}</span>
      <svg @click="logout" alt="退出登录" style="margin-left:10px;margin-top:3px;cursor: pointer;" width="20px" height="15px" viewBox="0 0 20 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <title>退出</title>
          <g id="页面-1" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
              <g id="基地管理" transform="translate(-1400.000000, -23.000000)" fill="#000000" fill-rule="nonzero">
                  <g id="退出" transform="translate(1400.000000, 23.000000)">
                      <path fill="currentColor" d="M19.9558826,7.27269985 L14.5133763,10.901122 L14.5133763,8.17977121 L5.4425454,8.17977121 L5.4425454,6.3657066 L14.5133763,6.3657066 L14.5133763,3.64445344 L19.9558826,7.27269985 Z M9.97796083,1.83029117 L1.81420133,1.83029117 L1.81420133,12.7152062 L9.97796083,12.7152062 L9.97796083,9.99395301 L11.7921231,9.99395301 L11.7921231,14.5294661 L9.97796083,14.5294661 L1.81420133,14.5294661 L3.90625455e-05,14.5294661 L3.90625455e-05,12.7152062 L3.90625455e-05,1.83029117 L3.90625455e-05,0.0160312462 L1.81420133,0.0160312462 L9.97796083,0.0160312462 L11.7921231,0.0160312462 L11.7921231,4.55154434 L9.97796083,4.55154434 L9.97796083,1.83029117 L9.97796083,1.83029117 Z" id="形状"></path>
                  </g>
              </g>
          </g>
      </svg>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, watch } from "vue";
import { UserFilled } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
export default {
  components: { UserFilled, },
  props: {
    index: Number,
    list: Array,
  },
  setup(props, context) {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const headerTitle = ref(process.env.VUE_APP_PUBLIC_TITLE || "");
    const userName = ref("");
    if (proxy.getCookie("userInfo")) {
      userName.value = JSON.parse(proxy.getCookie("userInfo")).user.loginName;
    }
    console.log(props.list);
    const menuList = ref(props.list);
    const selectIndex = ref(props.index);
    watch(
      () => props.index,
      (newValue) => {
        selectIndex.value = newValue;
      }
    );
    watch(
      () => props.list,
      (newValue) => {
        menuList.value = newValue;
      }
    );
    const selectId = ref("");
    const selectMenu = (e, index) => {
      selectIndex.value = index;
      selectId.value = e.id;
      context.emit("change", {
        index: selectIndex.value,
        type: menuList.value[selectIndex.value].type,
      });
    };
    const logout = () => {
      //退出
      proxy
        .$confirm("确定要退出登录吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          proxy
          .$request({
            url: "LOGOUT",
            data: {},
          })
          .then((res) => {
            if (res.data.code == "000") {
              proxy.setCookie("userInfo", "");
              router.push({
                path: "login",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
        })
        .catch(() => {
        });
    };
    return {
      headerTitle,
      userName,
      logout,
      menuList,
      selectIndex,
      selectId,
      selectMenu,
    };
  },
};
</script>

<style scoped lang="less">
.topHeader {
  padding: 10px 18px;
  display: flex;
  justify-content: space-between;
  .left {
    font-size: 24px;
    color: #3f4156;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }
  .midList {
    font-size: 16px;
    color: #222222;
    display: flex;
  }
  .right {
    color: #3f4156;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    .el-icon {
      margin-left: 25px;
      cursor: pointer;
    }
    .userName {
      margin-left: 5px;
    }
  }
  .midLi {
    margin: 0 20px;
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #222222;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .line_w{
      display: flex;
      margin-top: 4px;
      width: 24px;
      height: 3px;
      background: #fff;
    }
  }
  .active {
    color: #3e7fff;
    .line_w{
      background: #3e7fff;
    }
  }
}
</style>
