<template>
	<div class="pageShow" :style='"padding-top:" + top + "px"'>
		<div class="top_fixed">
			<div class="fixed_bg" :class="['bg-color-b-' + colorValue]"></div>
		</div>
		<div :style="{ 'min-height': height ? 'inherit' : '60vh', 'height': height ? height + 'px' : 'inherit', 'display': 'block' }"
			:class="['allGroup_r', 'theme-color-b-' + colorValue]">
			<div v-for="(element, index) in list" :key="index">
				<div class="item move" :id="'com' + index">
					<div class="compList">
						<component :goodsData="goodsData" :data="data" :is='element.name' class="mar_b_14" :classShow="classShow"
							v-bind:unitData='element.unitData' :colorValue="colorValue">
						</component>
					</div>

				</div>
			</div>
		</div>
		<div class="fixedD">
			<div class="navigationList clearfix">
				<div class="navigationLi fl" @click="toHref('com' + navigationLi.id, navigationLi.id)"
					:class="navigationLi.id == showIndex ? 'theme-color-button-' + colorValue + ' activeN' : ''"
					:style="{ 'width': navList.length > 4 ? (30 - 1) / (navList.length > 4 ? 4 : navList.length) + 'rem' : 31 / (navList.length > 4 ? 4 : navList.length) + 'rem' }"
					v-for="(navigationLi, navigationIndex) in navList.length > 4 ? navList.slice(0, 4) : navList">
					{{ navigationLi.unitData.floorTit }}
				</div>
				<el-dropdown trigger="click" v-if="navList.length > 4">
					<span class="el-dropdown-link">
						<el-icon class="el-icon--right">
							<ArrowUp />
						</el-icon>
					</span>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item command="a" @click="toHref('com' + moreLi.id)"
								v-for="(moreLi, moreIndex) in navList.length > 4 ? navList.slice(4, navList.length) : []">{{
									moreLi.unitData.floorTit }}</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>

			</div>
		</div>
	</div>
</template>

<script>
//disabled true禁止拖动  false允许
//top 组件配置的padding-top
//activeIndex  选中的模块
//listLeft   左侧模块的数据
//list   编辑的数据
import vuedraggable from 'vuedraggable'
import moduli from '@/components/customPage/showCom/moduli.vue'
import banner from '@/components/customPage/showCom/banner.vue'//轮播图
import shopInfo from '@/components/customPage/showCom/shopInfo.vue'//店铺信息
import shopMagic from '@/components/customPage/showCom/shopMagic.vue'//魔方
import shopFloor from '@/components/customPage/showCom/shopFloor.vue'//楼层
import searchPanel from '@/components/customPage/showCom/searchPanel.vue'//搜索
import shortcutMenu from '@/components/customPage/showCom/shortcutMenu.vue'//图文导航
import title from '@/components/customPage/showCom/title.vue'//标题文本
import textarea from '@/components/customPage/showCom/textarea.vue'//富文本
import separate from '@/components/customPage/showCom/separate.vue'//辅助分隔
import video from '@/components/customPage/showCom/video.vue'//运营视频
import image from '@/components/customPage/showCom/image.vue'//运营图片
import notice from '@/components/customPage/showCom/notice.vue'//店铺公告
import goodsFloor from '@/components/customPage/showCom/goodsFloor.vue'//店铺公告
import { ArrowUp } from '@element-plus/icons-vue'


export default {
	name: "pageShow",
	props: {
		data: {
			type: Object,
			default() {
				return {};
			}
		},
		disabled: {
			type: Boolean,
			default() {
				return false;
			}
		},
		top: {
			type: Number,
			default() {
				return 0;
			}
		},
		listLeft: {
			type: Array,
			default() {
				return [];
			}
		},
		list: {
			type: Array,
			default() {
				return [
				];
			}
		},
		activeIndex: {
			type: Number,
			default() {
				return -1;
			}
		},
		indexList: {
			type: Object,
			default() {
				return {};
			}
		},
		colorValue: {
			type: String,
			default() {
				return '';
			}
		},
		classShow: {
			type: Boolean,
			default() {
				return false;
			}
		},
		searchFlag: {
			type: Boolean,
			default() {
				return false;
			}
		},
		searchIndex: {
			type: Number,
			default() {
				return -1;
			}
		},
		height: {
			type: Number,
			default() {
				return 0;
			}
		},
		goodsData: {
			type: Array,
			default() {
				return [
				];
			}
		},
	},
	components: {
		vuedraggable,
		moduli,
		banner,
		shopInfo,
		shopMagic,
		shopFloor,
		searchPanel,
		shortcutMenu,
		title,
		textarea,
		separate,
		video,
		image,
		notice,
		goodsFloor,
		ArrowUp
	},
	data() {
		return {
			navigationList: [
				{ name: '品质特征' },
				{ name: '外在特征' },
				{ name: '特定产区' },
				{ name: '独特生产方式' },
			],
			showIndex: 0,
			scorllHeight: 400,
			moveId: -1,
			indexData: {
				"searchPanel": {
					"cont": 1
				},
				"shopInfo": {
					"cont": 1
				},
				"shortcutMenu": {
					"cont": 5
				},
				"banner": {
					"cont": 5
				},
				"shopFloor": {
					"cont": 15
				},
				"goodsList": {
					"cont": 1
				},
				"shopMagic": {
					"cont": 10
				},
				"title": {
					"cont": 10
				},
				"textarea": {
					"cont": 10
				},
				"separate": {
					"cont": 10
				},
				"video": {
					"cont": 10
				},
				"notice": {
					"cont": 10
				}
			},
			themeList: [
				{
					"id": "1",
					"styleName": "能量太阳橙",
					"colorValue": "orange",
					"sort": 0,
					"remarks": null,
					"delFlag": "0"
				},
				{
					"id": "2",
					"styleName": "生机春绿色",
					"colorValue": "green",
					"sort": 1,
					"remarks": null,
					"delFlag": "0"
				},
				{
					"id": "3",
					"styleName": "灵动水蓝色",
					"colorValue": "turquoise",
					"sort": 2,
					"remarks": null,
					"delFlag": "0"
				},
				{
					"id": "4",
					"styleName": "开朗金黄色",
					"colorValue": "yellow",
					"sort": 3,
					"remarks": null,
					"delFlag": "0"
				},
				{
					"id": "5",
					"styleName": "魅力天空蓝",
					"colorValue": "blue",
					"sort": 4,
					"remarks": null,
					"delFlag": "0"
				},
				{
					"id": "6",
					"styleName": "温馨浅绯色",
					"colorValue": "red",
					"sort": 5,
					"remarks": null,
					"delFlag": "0"
				}
			],
			overIndex: -1,
			creatList: [],
			salesList: [],
		}
	},
	mounted() {
		this.scorllHeight = window.innerHeight - 60;
		// this.goodsInit('createDate')//新品
	},
	watch: {
		list() {
			console.log(this.list, 'watch')
		}
	},
	computed: {
		navList: {
			get() {
				let Array = []
				this.list.forEach((e, i) => {
					if (e.name == "shopFloor") {
						e.id = i
						Array.push(e)
					}
				})
				return Array
			}
		},
	},
	methods: {
		toHref(selector, id) {
			this.showIndex = id
			let member = document.getElementById(selector);
			member.scrollIntoView();

		},
		getCss() {
			return '50px'
		},
		selectInit(selectD, index) {
			this.list[index].unitData.selectData = []
			this.request({
				url: Action.PRO_FLOORGOODSLIST, //,
				method: "post",
				data: {
					'commPubIds': selectD
				},
			})
				.then((res) => {
					let resp = res.data;
					if (resp.code == "000") {
						this.list[index].unitData.selectData = resp.result && resp.result.length > 0 ? resp.result : [];
						// this.selectData = resp.result&&resp.result.list&&resp.result.list.length>0?resp.result.list:[];
					} else {
						// this.hitAlert(res.data.msg);
					}
				})
				.catch((err) => {
					// console.log(err);
				});
		},
		//获取热销与新品
		goodsInit(orderBy) {
			// let orderBy = this.unitData.from=='2'?'createDate':'salesVolume'
			// let Arr = []
			// Arr.push(merchantId)
			this.selectData = []
			this.request({
				url: Action.BUYER_SHOP_SEARCHHOTPRODUCT, //,
				method: "post",
				data: {
					"pageInfo": {
						"orderBy": "create_date desc",
						"pageNo": 1,
						"pageSize": 10
					},

					"orderBy": orderBy,
					"membIds": [this.getCookie('userID')],
					"sortOrder": 'DESC',//排序模式 ASC或DESC,大小写均可
				},
			})
				.then((res) => {
					let resp = res.data;
					if (resp.code == "000") {
						// if(orderBy=='createDate'){
						this.creatList = resp.result.createDate;
						this.salesList = resp.result.salesVolume

						// 	setTimeout(()=>{ this.goodsInit('salesVolume'); },300)
						// }else if(orderBy=='salesVolume'){
						// 	this.salesList = resp.result&&resp.result.list&&resp.result.list.length>0?resp.result.list:[];
						// }
						console.log(this.creatList, this.selectData, 'TEST')
						// this.selectData = resp.result&&resp.result.list&&resp.result.list.length>0?resp.result.list:[];
					} else {
						// this.hitAlert(res.data.msg);
					}
				})
				.catch((err) => {
					// console.log(err);
				});
		},
		//点击组件
		selectIndex(index) {
			console.log(index, this.list)
			// if(this.activeIndex == index)return
			this.overIndex = -1
			this.overIndex = index
			this.$emit('select', index)
		},
		cssIn(index) {
			this.overIndex = index
		},
		cssOut(index) {
			this.overIndex = -1
		},
		//删除组件
		deletCom(index) {
			this.list.splice(index, 1)
			this.$emit('change', this.list, 2)
		},
		//复制组件
		copyCom(index) {
			let M = 0
			this.list.forEach(e => {
				if (e.name == this.list[index].name) {
					M++
				}
			});
			console.log(M, '组件数量', this.indexData[this.list[index].name].cont)
			if (this.indexData[this.list[index].name].cont == M) {
				this.$alert('该组件数量已达上限')
				return
			}
			// console.log(M)
			this.list.push(JSON.parse(JSON.stringify(this.list[index])))
			this.$emit('change', this.list, 2)
		},
		//右边往左边拖动时的事件
		end2(e) {
			console.log(this.list, '变化lema')
			// var that = this;
			// var items = this.arr1.filter(function(m) {
			// 	return m.id == that.moveId
			// })
			// //如果左边
			// if (items.length < 2) return;
			// this.arr1.splice(e.newDraggableIndex, 1)
		},
		//move回调方法
		onMove(e, originalEvent) {
			if (e.relatedContext.list == this.listLeft) return false;
			// if(e.relatedContext.element.id==9) return false;
			console.log(this.list, '变化lema')
			return true;
		},
	}
}
</script>

<style lang="less" scoped>
.pageShow {
	padding-bottom: 1.5rem;
	// width: 518px;
	.topBoxD {
		background: linear-gradient(#ff8f3e, #fbb684);
		position: fixed;
		top: 60px;
		z-index: 3;
	}

	//主题色配置
	.theme-color-orange {
		background: linear-gradient(#ff8f3e, #fbb684);
	}

	.theme-color-green {
		background: linear-gradient(#55af48, #93ca8c);
	}

	.theme-color-turquoise {
		background: linear-gradient(#0cbbca, #66d1da);
	}

	.theme-color-yellow {
		background: linear-gradient(#faa721, #f7cb6e);
	}

	.theme-color-red {
		background: linear-gradient(#e35050, #e99090);
	}

	.theme-color-blue {
		background: linear-gradient(#027cfe, #6eacfb);
	}

	.zIndex_0 {
		z-index: 0;
	}

	.fixedD {
		width: 100%;
		background-color: #ffffff;
		position: fixed;
		bottom: 0;
		overflow-y: hidden;

		.navigationList {
			width: 31rem;
			margin: 0 auto;
			height: 3.54rem;
			padding: .6rem 0;
			// display: flex;
			// justify-content: space-between;
			.navigationLi {
				// display: flex;
				// justify-content: center;
				display: inline-block;
				height: 3.54rem;
				line-height: 3.54rem;
				text-align: center;
				background-color: #ffffff;
				border-radius: 4px;
				font-family: PingFangSC-Medium;
				font-size: 1.1rem;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #151515;
			}

			.activeN {
				// background: linear-gradient(to right,#ffab7a,#ff7648);
				color: #ffffff;
				font-family: PingFangSC-Semibold;
				font-size: 12px;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0px;
			}
		}
	}
}

// .btnCom{
// 	width: 136px;
// 	height: 110px;
// 	position: absolute;
// 	margin-top: -20px;
// 	margin-left: 33.54rem;
// }
.popQp {
	width: 116px;
	height: 110px;
	padding-top: 22px;
	margin-left: 20px;
	background-image: url('../../../assets/images/shopEdit/qipao.png');
	background-size: 100% 100%;

	.popBtn {
		width: 56px;
		height: 28px;
		line-height: 28px;
		text-align: center;
		margin-left: 32px;
		font-size: 12px;
		border-radius: 2px;
		cursor: pointer;
	}

	.sc {
		background: #FB9B5B;
		color: #fff;
		// margin-top: 22px;
	}

	.fz {
		margin-top: 10px;
		border: solid 1px#DCDFE6;
		color: #333333;
	}
}

.statusBar {
	width: 375px;
	height: 44px;
	/* background-image: url('../../../assets/images/mobile.png'); */
	background-size: 100%;

	// background: #f5562e;
	img {
		width: 375px;
		height: 44px;
	}
}

.titleCapsule {
	padding-top: 10px;
	height: 50px;
	width: 375px;
	// background: #f5562e;
	// line-height: 50px;
	// text-align: center;
	color: #fff;
	font-size: 17px;

	.title {
		position: absolute;
		width: 375px;
		text-align: center;
		height: 29px;
		line-height: 29px;
	}

	img {
		width: 80px;
		height: 28px;
		position: absolute;
		margin-left: 284px;
	}
}

.allGroup_r {
	width: 100%;
	height: 800px;
	// overflow-y: scroll;
	box-shadow: 0rpx 4rpx 8rpx 0rpx #e8e8e8;
	background: transparent;
	// margin-top: -1px;
	// border: 1px solid #eee;
	overflow-y: scroll;
	padding-bottom: 3rem;
	padding-top: 1.5rem;
	background-attachment: fixed;

	.mar_b_14 {
		width: 31rem;
		// margin-bottom: 14px;
	}
}

.allGroup_r::-webkit-scrollbar {
	display: none;
	/* Chrome Safari */
}

.allGroup_r {
	scrollbar-width: none;
	/* firefox */
	-ms-overflow-style: none;
	/* IE 10+ */
	overflow-x: hidden;
	overflow-y: scroll;
}

// .allGroup_r::after{
// 	content: '';
// 	position: absolute;
// 	top: 0px;
// 	margin-left: 0px;
// 	width: 100%;
// 	height: 360px;
// 	// background-color: #ffe3d1;
// 	z-index: -1;
// }

.allGroup_r_new {
	width: 518px;
	height: 800px;
	// overflow-y: scroll;
	box-shadow: 0rpx 4rpx 8rpx 0rpx #e8e8e8;
	padding: 0 14px 0 0;
	background: transparent;
	margin-left: 1px;
	margin-top: 150px;
	overflow-y: scroll;

	.mar_b_14 {
		margin-bottom: 14px;
		width: 345px;
	}
}

// .allGroup_r_new::after{
// 	content: '';
// 	position: absolute;
// 	top: 205px;
// 	margin-left: -1px;
// 	width: 375px;
// 	height: 400px;
// 	background: linear-gradient(0deg, #ffffff 1%, #fbb684);;
// 	z-index: -1;
// }


.active {
	border: 1px #1772b4 dashed;

	.goods-item {
		width: 163.5px !important;
	}
}

.searchFixed {
	padding-top: 120px;
}

.haveFixed {
	height: 145px;
}

.el-dialog__body {
	.topBoxD {
		width: 375px;
		display: block;
		z-index: 0;
		position: relative;
		top: initial;
	}

	.allGroup_r {
		width: 375px;
		margin-top: 0;
		border: none;
		position: relative;
		z-index: 0;
		// background-color: #ffe3d1;
	}

	.allGroup_r_new {
		width: 375px;
		margin-top: 0;
		border: none;
		position: relative;
		z-index: 0;
		background: #f1f1f1;
	}

	.pageShow {
		width: 375px;
	}

	.btnCom {
		display: none;
	}

	.allGroup_r::after {
		top: 0px;
		z-index: -1;
	}
}

.el-dropdown {
	margin-top: 1.27rem;
	width: 2rem;
}

.top_fixed {
	width: 100%;
	height: 1.5rem;
	position: fixed;
	top: 0;
	z-index: 2;
	.fixed_bg {
		width: 100%;
		height: 1.5rem;
		margin: 0 auto;
	}
}



//主题背景色下半部
.theme-color-b-orange {
	background: linear-gradient(#fbb684, #f5f5f5);
	background-attachment: fixed;
}

.theme-color-b-green {
	background: linear-gradient(#93ca8c, #f5f5f5);
	background-attachment: fixed;
}

.theme-color-b-turquoise {
	background: linear-gradient(#66d1da, #f5f5f5);
	background-attachment: fixed;
}

.theme-color-b-yellow {
	background: linear-gradient(#f7cb6e, #f5f5f5);
	background-attachment: fixed;
}

.theme-color-b-red {
	background: linear-gradient(#e99090, #f5f5f5);
	background-attachment: fixed;
}
.theme-color-b-blue{
	background: linear-gradient(#6eacfb, #f5f5f5);
	background-attachment: fixed;
}

// .theme-color-b-orange::after {
// 	background: linear-gradient(#fbb684, #f5f5f5);
// }

// .theme-color-b-green::after {
// 	background: linear-gradient(#93ca8c, #f5f5f5);
// }

// .theme-color-b-turquoise::after {
// 	background: linear-gradient(#66d1da, #f5f5f5);
// }

// .theme-color-b-yellow::after {
// 	background: linear-gradient(#f7cb6e, #f5f5f5);
// }

// .theme-color-b-red::after {
// 	background: linear-gradient(#e99090, #f5f5f5);
// }

// .theme-color-b-blue::after {
// 	background: linear-gradient(#6eacfb, #f5f5f5);
// }

.bg-color-b-blue {
	background: linear-gradient(#6eacfb, #f5f5f5);
}


.bg-color-b-orange {
	background: #fbb684;
}

.bg-color-b-green {
	background: #93ca8c;
}

.bg-color-b-turquoise {
	background: #66d1da;
}

.bg-color-b-yellow {
	background: #f7cb6e;
}

.bg-color-b-red {
	background: #e99090;
}

.bg-color-b-blue {
	background: #6eacfb;
}

</style>
