let Api = {
	// 优选连接
	"PLINKINFO_FINDPAGE": "/difam/w/pLinkInfo/findPage", //分类列表
	"PLINKINFO_SAVE": "/difam/w/pLinkInfo/save", //根据产品ID(P_geo_id) 保存优选链接状态
	"PLINKPROPERTYINFO_SAVE": "/difam/w/PLinkPropertyInfo/save", //保存属性信息
	"PLINKPROPERTYINFO_GET": "/difam/w/PLinkPropertyInfo/get", //保存属性信息
	// 品质评价
	"PLINKINFO_FINDPAGEFORPZPJ": "/difam/w/pLinkInfo/findPageForPzpj", //分类列表（品质评价）
	"PLINKINFO_PZPJSAVE": "/difam/w/pLinkInfo/pzpjSave", //根据产品ID(P_geo_id) 保存品质评价状态
	"PLINKPROPERTYINFO_PZPJSAVE": "/difam/w/PLinkPropertyInfo/pzpjSave", //品质评价-保存属性信息
	"PLINKPROPERTYINFO_GETPZPJ": "/difam/w/PLinkPropertyInfo/getPzpj", //根据ID查询详细信息(品质评价)
	
   
}
module.exports = Api