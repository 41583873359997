<template>
	<div class="i-title">
		<div class="margin_tb_5rem" :class="{'title-nomal':true}" :style="{'text-align':unitData.position,'background-color':unitData.backgroundColor}">
			<!-- <div class="navbar-title">首页</div> -->
			<div class="i-title-name" :style="{'font-size':unitData.size*0.088+'rem','color':unitData.nameColor}">
				{{unitData.name}}
			</div>
            <div class="i-title-describe" :style="{'color':unitData.describeColor}">
                {{unitData.describe}}
            </div>
			<!-- <w-title v-model="keyword" :showAction="false" bgColor="#fff"
			:titleIconSize="36" titleIconColor="#a3a3a3" placeholderColor="#a3a3a3" :height="68"
			:disabled="disabled" :shape="shape" :placeholder="placeholder" :inputAlign="inputAlign" @tap="goPage()"></w-title> -->
		</div>
	</div>
</template>

<script>
	export default {
		name:"title-panel",
		props:{
			unitData:{
				type:Object,
				default:{
					name:'',
                    describe:'',
                    position:'left',
                    size:'16',
                    style:'14',
                    nameColor:'#000000',
                    describeColor:'#000000',
                    backgroundColor:'transparent',
                    more:false,
                    moreConfig:{
                        type:'',
                        
                    }
				}
			},
		},
		mounted(){
				// console.log(this.unitData)
		},
		data() {
			return {
				keyword:""
			};
		},
		methods:{
			goPage(){
				uni.navigateTo({
				   url: '/pages/commonPage/itemSearchPage/itemSearchPage?titleType=0'
				});
			}
		}
		
		
	}
</script>

<style lang="less">
.i-title{
	margin:0 auto;
	.title-nomal{
		// padding: 1.77rem 0;
	}
	.i-title-describe{
		margin-top: 0.89rem;
	}
}
</style>
