let Api = {
    "YOP_MERCHANT_FINDPAGELIST":"/w/yop/merchant/findPageList",//分页查询易宝商户列表
    "YOP_MERCHANT_UPLOADEXCEL":"/w/yop/merchant/uploadExcel",//上传易宝商户Excel
    "YOP_BILL_FINDTRADEPAGELIST":"/w/yop/bill/findTradePageList",//分页查询交易对账单列表
    "YOP_BILL_FINDDIVIDEPAGELIST":"/w/yop/bill/findDividePageList",//分页查询分账对账单列表
    "YOP_BILL_FINDRECONCILEPAGELIST":"/w/yop/bill/findReconcilePageList",//分页查询对账列表
    "YOP_BILL_INITBILL":"/w/yop/bill/initBill",//下载指定日期对账单


    "YOP_OTHER_REGSTATUSQUERY":"/w/yop/other/regStatusQuery",//查询企业入网状态
    "YOP_OTHER_WECHATQUERY":"/w/yop/other/wechatQuery",//查询商户绑定的appId
    "YOP_OTHER_WECHATBIND":"/w/yop/other/wechatBind",//商户绑定支付appId
    "YOP_OTHER_UPDATEPRODUCTINFO":"/w/yop/other/updateProductInfo",//修改商户开通的产品
    "YOP_OTHER_UPDATESETTLECARD":"/w/yop/other/updateSettleCard",//修改结算账户
    "YOP_OTHER_PRODUCTFEEQUERY":"/w/yop/other/productFeeQuery",//商户产品费率查询
    "YOP_OTHER_MERCHANTDISPOSEUNFREEZE":"/w/yop/other/merchantDisposeUnfreeze",//商户解冻
    "YOP_OTHER_UPLOADIMG":"/w/yop/other/uploadImg",//易宝上传图片
 }
module.exports = Api