<template>
	<div class="shop-magic">
		<div class="shop-magic-left-two" v-if="unitData.magicImgNum=='4'">
			<div class="img-wrap" @click="toHref(unitData.imgList[0].href)">
				<img v-if="unitData.imgList.length>1&&unitData.imgList[0].magiImgcUrl" :src="commenUrl+unitData.imgList[0].magiImgcUrl" />
				<div class="title" :style="{'margin-top':unitData.imgList[0].magiImgcUrl?'-2.66rem':'15.52rem'}" v-if="unitData.magicImgShow">{{unitData.imgList[0].title}}</div>
			</div>
			<div class="img-wrap" @click="toHref(unitData.imgList[2].href)">
				<img v-if="unitData.imgList.length>1&&unitData.imgList[2].magiImgcUrl" :src="commenUrl+unitData.imgList[2].magiImgcUrl"/>
				<div class="title" :style="{'margin-top':unitData.imgList[2].magiImgcUrl?'-2.66rem':'15.52rem'}" v-if="unitData.magicImgShow">{{unitData.imgList[2].title}}</div>
			</div>
		</div>
		<div class="shop-magic-left" v-else @click="toHref(unitData.imgList[0].href)">
			<img v-if="unitData.imgList.length>1&&unitData.imgList[0].magiImgcUrl" :src="commenUrl+unitData.imgList[0].magiImgcUrl"/>
				<div class="title" :style="{'margin-top':unitData.imgList[0].magiImgcUrl?'-2.66rem':'31.77rem'}" v-if="unitData.magicImgShow">{{unitData.imgList[0].title}}</div>
		</div>
		<div class="shop-magic-right">
			<div class="img-wrap" @click="toHref(unitData.imgList[1].href)">
				<!-- {{unitData.imgList[1]}} -->
				<img v-if="unitData.imgList.length>1&&unitData.imgList[1].magiImgcUrl" :src="commenUrl+unitData.imgList[1].magiImgcUrl"/>
				<div class="title" :style="{'margin-top':unitData.imgList[1].magiImgcUrl?'-2.66rem':'15.52rem'}" v-if="unitData.magicImgShow">{{unitData.imgList[1].title}}</div>
			</div>
			<div class="img-wrap" v-if="unitData.imgList.length>3" @click="toHref(unitData.imgList[3].href)">
				<!-- {{unitData.imgList[3]}} -->
				<img v-if="unitData.imgList.length>1&&unitData.imgList[3].magiImgcUrl" :src="commenUrl+unitData.imgList[3].magiImgcUrl"/>
				<div class="title" :style="{'margin-top':unitData.imgList[3].magiImgcUrl?'-2.66rem':'15.52rem'}" v-if="unitData.magicImgShow">{{unitData.imgList[3].title}}</div>
			</div>
			<div class="img-wrap" v-else @click="toHref(unitData.imgList[2].href)">
				<!-- {{unitData.imgList[2]}} -->
				<img v-if="unitData.imgList.length>1&&unitData.imgList[2].magiImgcUrl" :src="commenUrl+unitData.imgList[2].magiImgcUrl"/>
				<div class="title" :style="{'margin-top':unitData.imgList[2].magiImgcUrl?'-2.66rem':'15.52rem'}" v-if="unitData.magicImgShow">{{unitData.imgList[2].title}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name:"shopMagic",
		props:{
			unitData:{
				type:Object,
				default(){
					return{
						magicImgNum:'3',//魔方图片数量
						imgList:[{
							magicTit:'',//标题
							magiImgcUrl:'',//图片路径
						}]
					}
				}
			}
		},
		data() {
			return {
				commenUrl:this.commenUrl,
			};
		},
		methods:{
			toHref(e){
				if(e){
					window.location = e
				}
				
			}
		}
	}
</script>

<style lang="less" scoped>
.shop-magic{
	display: flex;
	height: 31rem;
	justify-content: space-between;
	margin:0 auto 10px;
	.shop-magic-left,.shop-magic-left img{
		width: 15rem;
		height: 31rem;
		background: #fff;
		border-radius: 0.89rem;
	}
	.shop-magic-left img{
		width: 15rem;
		height: 31rem;
		background: #fff;
		border-radius: 0.89rem;
	}
	.shop-magic-left{
		.title{
			font-size: 1.78rem;
			width: 17.73rem;
			text-align: left;
			color: #333;
			display: block;
			margin-top: 31.77rem;
			margin-left: 1.06rem;
		}
	}
	.shop-magic-left-two,.shop-magic-right{
		width: 15rem;
		height: 31rem;
		display: flex;
		flex-direction: column;
		.title{
			// position: absolute;
			margin-top: 15.52rem;
			font-size: 1.78rem;
			width: 17.73rem;
			text-align: left;
			color: #333;
			margin-left: 1.06rem;
		}
		.img-wrap{
			width: 15rem;
			height: 15rem;
			background: #fff;
			border-radius: 0.89rem;
			img{
				width: 15rem;
				height: 15rem;
				background: #fff;
				border-radius: 0.89rem;
			}
			&:last-child{
				margin-top: auto;
			}
		}
	}
}
</style>