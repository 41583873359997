let Api = {
    "EQU_CLIENT_FINDPAGELIST":"/w/equ/client/findPageList",//客户端列表
    "EQU_CLIENT_RESETCLIENT":"/w/equ/client/resetClient",//重置客户端私钥
    "EQU_CLIENT_ADDCLIENT":"/w/equ/client/addClient",//新增客户端
    "EQU_CLIENT_DEL":"/w/equ/client/delClient",//删除客户端
    "EQU_ENTP_FINDPAGELIST":"/w/equ/entp/findPageList",//分页查询设备厂商列表
    "EQU_ENTP_FINDLIST":"/w/equ/entp/findList",//查询所有设备厂商列表
    "EQU_ENTP_SAVEORUPDATE":"/w/equ/entp/saveOrUpdate",//保存或修改设备厂商
    "EQU_ENTP_DEL":"/w/equ/entp/del",//删除设备厂商
    "EQU_EQUINFO_FINDPAGELIST":"/w/equ/equInfo/findPageList",//分页查询设备列表
    "EQU_EQUINFO_SAVEORUPDATE":"/w/equ/equInfo/saveOrUpdate",//保存或修改设备
    "EQU_EQUINFO_CHECKEQUEXIST":"/w/equ/equInfo/checkEquExist",//校验设备是否存在
    "EQU_EQUINFO_FINDINFO":"/w/equ/equInfo/findInfo",//查询设备详细信息
    "EQU_EQUINFO_DEL":"/w/equ/equInfo/del",//删除设备
}
module.exports = Api