let Api = {
    "CATEGORYMAGE_MAGETREE":"/system/w/categoryMage/findCommMageTree",//品类列表
    "COMMVARIETMAGE_FINDLIST":"/system/w/commVarietMage/findCommVarierMageListNoPage",//查询商品品种列表
	"PLACEMAGE_FINDPAGELIST": "/system/w/placeMage/findPageList", //分页查询产地列表
    "PLACEMAGE_EXPORTPLACE": "/system/w/placeMage/exportPlace", //导出产地列表
    "PLACEMAGE_FINDINFO": "/system/w/placeMage/findInfo", //查询产地详情
    "PLACEMAGE_SAVEORUPDATE":"/system/w/placeMage/saveOrUpdate",//新增或修改产地信息
    "PLACEMAGE_MEMBPAGELIST":"/system/w/placeMage/findPlaceMembPageList",//分页查询产地用户列表
    "PLATMEMB_RESETPWD":"/system/w/platMemb/resetPwd",//重置密码
}
module.exports = Api