<template>
	<div class="shop-floor">
		<div class="floor-tit-img" :class="'theme-color-floor-'+colorValue" v-if="unitData.titType=='img'">
			<img class="imgTit" @tap="goPage" v-if="unitData.titImgUrl" :src="commenUrl+unitData.titImgUrl" mode=""/>
		</div>
		<div class="floor-tit-txt" :style="{'background-image':unitData.titImgUrl?'url(\''+commenUrl+unitData.titImgUrl+'\')':''}" :class="'theme-color-floor-'+colorValue" v-else>
			<div class="tit">{{unitData.floorTit}}</div>
			<div class="more">{{unitData.introduce}}</div>
			<!-- <div class="more" @tap="goPage">
				<span>更多</span>
				<span class="icon">></span>
			</div> -->
		</div>
	</div>
</template>

<script>
	export default {
		name:"shop-floor",
		props:{
			// 楼层信息
			unitData:{
				type:Object,
				default(){
					return {
						goodsFloor:[
							// {'productName':'黑龙江大葡萄味美汁多，好吃极了',minPrice:199.99,titImgUrl:''}
						],//商品列表
						floorTit:'',//商品名称
						titType:'txt',//显示样式(标题楼层/图片楼层)
						titImgUrl:'',//图片楼层图片路径
						titLine:'2',//名称显示行数(一行显示/两行显示)
						from:'1',
						selectData:[]
					};
				}
			},
			colorValue:{
				type:String,
				default(){
					return 'orange';
				}
			}
		},
		data() {
			return {
				commenUrl:this.commenUrl,
				selectData:[],
				creatList:[],
				salesList:[],
			};
		},
		watch:{
			selectD(){
				// this.selectInit();
			},
			unitFrom(){
				if(this.unitData.from=='0'){
					// this.selectInit()
				}
				// this.selectData = []
				// if(this.unitData.from=='0'){
				// 	this.selectInit()
				// }else if(this.unitData.from=='1'){
				// 	this.selectData = this.salesList;
				// }else{
				// 	this.selectData = this.creatList;
				// }
				// console.log(this.selectData,1)
			},
		},
		computed: {
			selectD(){
				return this.unitData.goodsFloor
			},
			unitFrom(){
				return this.unitData.from
			},
		},
		mounted() {
			// if(this.unitData.from=='0'){
			// 	this.selectInit()
			// }else if(this.unitData.from=='1'){
			// 	this.selectData = this.salesList;
			// }else{
			// 	this.selectData = this.creatList;
			// }
			// console.log(this.selectData,2)
			
		},
		methods:{
		}
	}
</script>

<style lang="less">
.shop-floor{
	margin:0 auto;
	.floor-tit-img{
		height: 8.87rem;
		.imgTit{
			background-color: #ececec;
			border-top-left-radius: 0.89rem;
			border-top-right-radius: 0.89rem;
			width: 100%;
			height: 8.87rem;
		}
	}
	.floor-tit-txt{
		padding: 2.21rem 1.06rem;
		background-color: #66d1da;
		// display: flex;
		// justify-content: space-between;
		border-top-left-radius: 0.89rem;
		border-top-right-radius: 0.89rem;
		height: 6.65rem;
		background-size: 100% 100%;
    	background-repeat: no-repeat;
		.tit{
			width: 28.77rem;
			height: 2.66rem;
			line-height: 2.66rem;
			font-family: AlimamaShuHeiTi-Bold;
			font-size: 1.77rem;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0;
			color: #ffffff;
			margin-bottom: 0.67rem;
		}
		.more{
			width: 28.77rem;
			height: 1.88rem;
			font-family: PingFangSC-Regular;
			font-size: 1.06rem;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0;
			color: #ffffff;
		}
	}
	//楼层主题色
	.theme-color-floor-orange{
		background-color:#fbb684;
	}
	.theme-color-floor-green{
		background-color:#93ca8c;
	}
	.theme-color-floor-turquoise{
		background-color:#66d1da;
	}
	.theme-color-floor-yellow{
		background-color:#f7cb6e;
	}
	.theme-color-floor-red{
		background-color:#e99090;
	}
	.theme-color-floor-blue{
		background-color:#6eacfb;
	}
	.floor-swiper{
		.scroll-parent{
			background: #fff;
			border-bottom-left-radius: 0.89rem;
			border-bottom-right-radius: 0.89rem;
			white-space: nowrap;
			width: 100%;
			overflow-x: scroll;
			.parent-list{
				margin-right: 1.11rem;
				display: inline-block;
				font-size: 1.06rem;
				padding: 1.06rem 0 1.06rem 0;
				.parent-list-img{
					padding-bottom: 1.06rem;
					img{
						background: #ececec;
						width: 9.98rem;
						height: 9.98rem;
						border-radius: 0.44rem;
					}
				}
				.parent-list-txt{
					height: 1.77rem;
					font-weight: 900;
					width:9.98rem;
					display: block;
					font-size: 1.06rem;
					line-height: 1.77rem;
					color: #333;
					overflow: hidden;
					word-break:break-all;
					text-overflow:ellipsis;
					white-space:nowrap;
				}
				.parent-list-txt-line2{
					height: 3.55rem;
					overflow: hidden;
					white-space: normal;
					text-overflow: ellipsis;
					display:-webkit-box;
					-webkit-box-orient:vertical;
					-webkit-line-clamp:2;
				}
				.parent-list-money{
					margin-top:0.89rem;
					color: #e83538;
					font-size:1.06rem;
					word-break: break-all;
					.price{
						font-size: 1.66rem;
						font-weight: bold;
						margin-left: 0.33rem;
					}
				}
			}
			.parent-list:first-child{
				padding-left: 1.06rem;
			}
			::-webkit-scrollbar{//隐藏scroll滚动条
				display: none;
				width: 0!important;
				height: 0!important;
				color: transparent;
			}
		}
	}

}
</style>