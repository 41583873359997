<template>
  <div class="homePage" :style="'height:' + height + 'px'">
    <Header :index="selectIndex" @change="menuChange" :list="menuList"></Header>
    <div class="container" :style="'height:' + (height - 52) + 'px'">
      <Menu :list="selectList" :style="'height:' + (height - 52) + 'px'"></Menu>
      <div class="rightContainer">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import Header from "../components/topHeader/topHeader.vue";
import Menu from "../components/leftMenu/leftMenu.vue";

export default {
  name: "homePage",
  components: { Header, Menu },
  setup() {
    const { proxy } = getCurrentInstance();
    const height = ref(document.documentElement.clientHeight);
    // 监听浏览器尺寸变化
    onMounted(() => {
      window.addEventListener("resize", () => {
        // 监听页面尺寸变化
        console.log(document.documentElement.clientHeight);
        height.value = document.documentElement.clientHeight;
      });
    });
    const menuList = ref([]);
    const selectList = ref([]);
    const router = useRouter();
    const route = useRoute()
    const selectIndex = ref(0);
    const menuType = ref('')
    const menuChange = (e) => {
      // selectIndex.value = e.index;
      menuType.value = e.type
      menuInit(0)
    };
    const menuInit = (type) => {
      //type为0则是默认处理,为1则为回显
      //顶部菜单初始化
      proxy
        .$request({
          url: "AUTH_LIST",
          data: {
            type: menuType.value,
          },
        })
        .then((res) => {
          selectList.value = res.data.result;
          if (type === 0) {
            if (selectList.value[0].children[0].children[0].href) {
              router.push(selectList.value[0].children[0].children[0].href);
            } else {
              proxy.$message({
                message: "暂未配置地址",
                center: true,
                type: "error",
                duration: 2000,
              });
            }
          }

          // menuList.value = res.data.result.find(e=>e.id==id)?.children
        })
        .catch((err) => {
          console.log(err);
        });
    };


    const topMenuInit = () => {
      //顶部菜单初始化
      proxy
        .$request({
          url: 'MENUSERVICE_LIST',
          data: {
            type: 'ope_admin'
          },
        })
        .then((res) => {
          menuList.value = res.data.result
          if (route.path == '/') {
            //刚登录不做处理直接进默认
            menuType.value = menuList.value[0].type
            menuInit(0)
          } else {
            //回显菜单
            let mrIndex = menuList.value.findIndex(e => e.name == route.name.list[0])
            if(mrIndex==-1)mrIndex=0
            selectIndex.value = mrIndex
            menuType.value = menuList.value[mrIndex].type
            menuInit(1)
          }
          //默认选中

        })
        .catch((err) => {
          console.log(err)
        });
    }
    topMenuInit()
    // menuInit();
    return {
      height,
      menuChange,
      selectIndex,
      menuList,
      selectList,
    };
  },
};
</script>
<style scoped lang="less">
.homePage {
  overflow: hidden;

  .container {
    display: flex;
    border-top: 1px solid #f7f8fa;
  }

  .rightContainer {
    flex-grow: 1;
    background-color: #f7f8fa;
    width: calc(100% - 240px);
  }
}
</style>
